import React from "react";
import { Box, Spinner } from "native-base";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-native";
// internal
import ImageSearchInput from "./image-search-input.component";
import ImageSearchResultList from "./image-search-result-list.component";
import { useUserDetails } from "src/features/users/hooks/useUserDetails";

export const ImageSearch = ({ setSearchTerm, searchTerm }) => {
  const userDetails = useUserDetails();
  const searchClient = algoliasearch(
    "1L6DKURPUI",
    userDetails?.search_api_key,
    {
      queryParameters: {
        hitsPerPage: 5,
      },
    }
  );
  if (userDetails) {
    return (
      <InstantSearch
        indexName="images"
        searchClient={searchClient}
        onSearchStateChange={setSearchTerm}
      >
        <ImageSearchInput />
        <Box pt={5} />
        {searchTerm?.query && <ImageSearchResultList />}
      </InstantSearch>
    );
  } else return <Spinner />;
};
