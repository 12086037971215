import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
// internal screens
import { AuthContext } from "src/services/auth.context";
import { ImageHomeScreen } from "src/features/images/screens/image-home.screen";
import { ImageListScreen } from "src/features/images/screens/image-list.screen";
import { ImageDetailScreen } from "src/features/images/screens/image-detail.screen";
import { ImageOnboardingScreen } from "src/features/images/screens/image-onboarding.screen";

const Stack = createStackNavigator();

export const ImageNavigator = () => {
  const { user, isLoading } = React.useContext(AuthContext);

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        cardStyle: {
          flex: 1,
        },
      }}
    >
      <Stack.Screen component={ImageHomeScreen} name="ImageHome" />
      <Stack.Screen component={ImageOnboardingScreen} name="ImageOnboarding" />
      {(user?.uid || isLoading) && (
        <Stack.Group>
          <Stack.Screen component={ImageListScreen} name="ImageList" />
          <Stack.Screen component={ImageDetailScreen} name="ImageDetail" />
        </Stack.Group>
      )}
    </Stack.Navigator>
  );
};
