import React from "react";
import * as Clipboard from "expo-clipboard";
import { Ionicons } from "@expo/vector-icons";
import { Icon, Text, HStack, IconButton, Toast } from "native-base";
import { TranslationsContext } from "src/services/translations.context";

export const CopyTextIConButton = ({
  text,
  size = "sm",
  color = undefined,
  safeAreaBottom = undefined,
}) => {
  const { i18n } = React.useContext(TranslationsContext);
  const onPress = async () => {
    await Clipboard.setStringAsync(text);
    Toast.show({
      placement: "top",
      bg: "primary.600",
      bgColor: "primary.300",
      title: i18n.t("general_copy_success_toast_title"),
    });
  };
  return (
    <HStack flex={1} justifyContent={"center"} alignItems="center">
      <Text
        px={2}
        color={color}
        fontSize={size}
        maxWidth={"80%"}

      >
        {text}
      </Text>
      <IconButton
        p={3}
        onPress={onPress}
        borderRadius={"full"}
        icon={<Icon as={Ionicons} name="copy" size={size} color={color} />}
      />
    </HStack>
  );
};
