import React from "react";
import { Platform } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import {
  Divider,
  Icon,
  useBreakpointValue,
  useColorMode,
  useTheme,
  VStack,
} from "native-base";
import {
  DrawerItem,
  DrawerItemList,
  createDrawerNavigator,
} from "@react-navigation/drawer";
// internal
import { AuthContext } from "src/services/auth.context";
import { useUserDetails } from "src/features/users/hooks/useUserDetails";
// internal navigators
import { ChatNavigator } from "src/navigation/chat.navigator";
import { UserNavigator } from "src/navigation/users.navigator";
import { ImageNavigator } from "src/navigation/image.navigator";
import { NavigationFooter } from "src/navigation/components/navigation-footer.component";
// internal components
import { Logo } from "src/theme/media/logo.component";
import { ToggleYamli } from "src/components/toggle-yamli.component";
import { ScreenFrame } from "src/theme/layout/screen-frame.component";
import { TranslationsContext } from "src/services/translations.context";
import { ToggleThemeButton } from "src/components/toggle-theme-button.component";
import { ToggleLanguageButtons } from "src/components/toggle-language-buttons.components";

const Drawer = createDrawerNavigator();

const DrawerNavigationItemIcon = (name, color) => {
  return <Icon size="lg" name={name + ""} color={color} as={Ionicons} />;
};

export const DrawerNavigation = () => {
  const { colors } = useTheme();
  const { colorMode } = useColorMode();
  const { user, onLogout } = React.useContext(AuthContext);
  const { i18n, localizeNumber } = React.useContext(TranslationsContext);
  const drawerType = useBreakpointValue({
    base: "slide",
    lg: "permanent",
  });
  return (
    <Drawer.Navigator
      initialRouteName="ImageHome"
      screenOptions={{
        headerShown: false,
        drawerType: drawerType,
        drawerStyle: {
          width: 250,
          shadowRadius: 1,
          shadowOpacity: 0.5,
          shadowColor:
            drawerType == "slide"
              ? "transparent"
              : colorMode == "light"
              ? colors?.gray["900"]
              : "transparent",
          border:
            drawerType == "slide"
              ? "none"
              : colorMode == "light"
              ? colors?.gray["100"]
              : colors?.gray["900"],
        },
        drawerActiveTintColor:
          colorMode == "light" ? colors?.gray["100"] : colors?.gray["900"],
        drawerInactiveTintColor:
          colorMode == "light" ? colors?.gray["900"] : colors?.gray["100"],
        drawerActiveBackgroundColor: colors.primary["400"],
        drawerLabelStyle: {
          textAlign: "left",
        },
        drawerItemStyle: {
          direction: i18n.locale == "ar" ? "rtl" : "ltr",
        },
      }}
      drawerContent={(props) => (
        <ScreenFrame headerIsShown={false} space={5}>
          {/* <Logo size="sm" /> */}
          <DrawerItemList {...props} />

          <VStack flex={1} justifyContent="flex-end" space={5}>
            {user?.uid && (
              <DrawerItem
                onPress={onLogout}
                label={i18n.t("user_logout")}
                style={{
                  direction: i18n.locale == "ar" ? "rtl" : "ltr",
                }}
                labelStyle={{
                  textAlign: "left",
                  color:
                    colorMode == "light" ? colors?.darkText : colors?.lightText,
                }}
                icon={({ color }) =>
                  DrawerNavigationItemIcon(
                    "log-out",
                    colorMode == "light" ? colors?.darkText : colors?.lightText
                  )
                }
              />
            )}
            <Divider />
            <ToggleThemeButton />
            {Platform.OS !== "web" && (
              <>
                <Divider />
                <ToggleYamli />
              </>
            )}

            <Divider />
            <ToggleLanguageButtons />
            <Divider />
            <NavigationFooter />
          </VStack>
        </ScreenFrame>
      )}
    >
      <Drawer.Screen
        name="ChatNavigator"
        component={ChatNavigator}
        options={{
          drawerLabel: i18n.t("drawer_navigation_chat"),
          drawerIcon: ({ color }) =>
            DrawerNavigationItemIcon("chatbubble-ellipses", color),
        }}
      />
      <Drawer.Screen
        name="ImageNavigator"
        component={ImageNavigator}
        options={{
          drawerLabel: i18n.t("drawer_navigation_image"),
          drawerIcon: ({ color }) => DrawerNavigationItemIcon("camera", color),
        }}
      />
      <Drawer.Screen
        name="UserNavigator"
        component={UserNavigator}
        options={{
          drawerLabel: user?.uid
            ? i18n.t("drawer_navigation_user")
            : i18n.t("user_login"),
          drawerIcon: ({ color }) =>
            DrawerNavigationItemIcon(user?.uid ? "person" : "log-in", color),
        }}
      />
    </Drawer.Navigator>
  );
};
