import React from "react";
import { HStack, VStack } from "native-base";
// internal context
import { AuthContext } from "src/services/auth.context";
// internal hooks
import { useChat } from "../hooks/useChat";
// internal components
import { ChatCreate } from "../components/chat-create.component";
import { ScreenFrame } from "src/theme/layout/screen-frame.component";
import { MessageList } from "src/features/messages/components/message-list.component";
import { MessageCreate } from "src/features/messages/components/message-create.component";
import { NavigateToChatListButton } from "src/features/chats/components/navigate-to-chat-list-button.component";
import { PersonaList } from "src/features/personas/components/persona-list.component";

export const ChatHomeScreen = ({ navigation }) => {
  const { user } = React.useContext(AuthContext);

  const [chatId, setChatId] = React.useState(null);
  const chat = useChat(chatId);
  return (
    <ScreenFrame
      space={0}
      safeAreaBottom={false}
      withScrollView={false}
      headerRightComponent={
        user && (
          <HStack
            _web={{
              pt: 2,
              pr: 2,
            }}
            alignItems={"center"}
            space={"sm"}
          >
            {chatId && <ChatCreate setChatId={setChatId} />}
            <NavigateToChatListButton navigation={navigation} />
          </HStack>
        )
      }
      footerComponent={
          <MessageCreate chatId={chatId} setChatId={setChatId} />
      }
    >
      <MessageList chat={chat} chatId={chatId} />
    </ScreenFrame>
  );
};
