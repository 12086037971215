import React from "react";
import { Ionicons } from "@expo/vector-icons";
import {
  Box,
  Text,
  Icon,
  Modal,
  VStack,
  Button,
  HStack,
  Divider,
  Pressable,
} from "native-base";
// internal
import { AuthContext } from "src/services/auth.context";
import { ScreenFrame } from "src/theme/layout/screen-frame.component";
import { TranslationsContext } from "src/services/translations.context";

const ListItem = ({
  text,
  iconName,
  size = "md",
  value = undefined,
  onPress = undefined,
  textColor = undefined,
  confirmationText = undefined,
  isConfirmationRequired = false,
  iconColor = "tertiary.600",
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { i18n } = React.useContext(TranslationsContext);
  return (
    <>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Modal.Content maxWidth="400px">
          <Modal.Header
            _dark={{
              bg: "gray.900",
            }}
            _light={{
              bg: "gray.50",
            }}
          >
            <HStack space={5} align>
              <Icon
                color={iconColor}
                as={Ionicons}
                name={iconName}
                size={size}
              />
              <Text color={iconColor}>{text}</Text>
            </HStack>
          </Modal.Header>
          <Modal.CloseButton />
          <Modal.Body
            _dark={{
              bg: "gray.900",
            }}
            _light={{
              bg: "gray.50",
            }}
          >
            <Text>{confirmationText}</Text>
          </Modal.Body>
          <Modal.Footer
            _dark={{
              bg: "gray.900",
            }}
            _light={{
              bg: "gray.50",
            }}
          >
            <Button.Group space={2}>
              <Button
                onPress={() => {
                  onPress();
                  setIsModalOpen(false);
                }}
                variant="ghost"
                colorScheme={"secondary"}
              >
                {i18n.t("general_yes_button")}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Pressable
        {...props}
        disabled={!onPress}
        onPress={() => {
          if (isConfirmationRequired) {
            setIsModalOpen(true);
          } else {
            onPress();
          }
        }}
        flexDir={i18n.locale === "ar" ? "row-reverse" : "row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <VStack space={1} flex={1}>
          <Text fontSize={size}  color={textColor}>
            {text}
          </Text>
          {value && <Text>{value}</Text>}
        </VStack>
        <Icon color={iconColor} as={Ionicons} name={iconName} size={size} />
      </Pressable>
    </>
  );
};

export const UserDetailScreen = ({ navigation }) => {
  const { i18n } = React.useContext(TranslationsContext);
  const {
    user,
    onLogout,
    onDeleteAccount,
    onSendValidationEmail,
    onSendPasswordResetEmail,
  } = React.useContext(AuthContext);
  return (
    <ScreenFrame py={5} px={5}>
      {!!user && (
        <VStack flex={1} width="100%" space={"2xl"} alignSelf={"center"}>
          <>
            {!user?.emailVerified && (
              <VStack p={3} space={3} borderRadius="xl" bg="tertiary.600">
                <HStack space={3}>
                  <Icon
                    mt={1}
                    as={Ionicons}
                    color={"lightText"}
                    name={"alert-circle"}
                  />
                  <Text
                    color={"lightText"}

                    maxWidth={"90%"}
                  >
                    {i18n.t("user_detail_email_not_verified")}
                  </Text>
                </HStack>
                <Button
                  bg="lightText"
                  variant={"unstyled"}
                  onPress={onSendValidationEmail}
                  _text={{
                    fontSize: "sm",
                    color: "darkText",
                  }}
                >
                  {i18n.t("user_detail_send_email_button")}
                </Button>
              </VStack>
            )}
            <VStack space={5}>
              <ListItem
                iconName={"mail"}
                value={user.email}
                text={i18n.t("user_login_or_register_email_input_placeholder")}
              />
              <ListItem
                iconName={"key"}
                value={"**********"}
                isConfirmationRequired={true}
                text={i18n.t("user_detail_reset_password")}
                onPress={() => onSendPasswordResetEmail(user?.email)}
                confirmationText={i18n.t(
                  "user_detail_reset_password_confirmation"
                )}
              />

              <ListItem
                size="sm"
                iconName={"trash"}
                textColor="gray.600"
                iconColor="gray.600"
                onPress={onDeleteAccount}
                isConfirmationRequired={true}
                text={i18n.t("user_detail_delete_button")}
                confirmationText={i18n.t("user_detail_delete_confirmation")}
              />
            </VStack>
          </>
        </VStack>
      )}
    </ScreenFrame>
  );
};
