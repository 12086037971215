import React from "react";

export const NotificationsContext = React.createContext();

export const NotificationsContextProvider = ({ children }) => {
  return (
    <NotificationsContext.Provider value={null}>
      {children}
    </NotificationsContext.Provider>
  );
};
