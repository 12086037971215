import React from "react";
import { Ionicons } from "@expo/vector-icons";
import * as WebBrowser from "expo-web-browser";
import { VStack, HStack, Icon, Text, Pressable } from "native-base";
import { TranslationsContext } from "src/services/translations.context";

const NavigationFooterPressable = ({ url, children }) => (
  <Pressable onPress={async () => await WebBrowser.openBrowserAsync(url)}>
    {children}
  </Pressable>
);

const NavigationFooterText = ({ text }) => (
  <Text fontSize={"xs"} color="muted.600" _dark={{ color: "muted.300" }}>
    {text}
  </Text>
);

const NavigationFooterIcon = ({ name }) => (
  <Icon
    name={name}
    as={Ionicons}
    color="muted.600"
    _dark={{ color: "muted.300" }}
  />
);

export const NavigationFooter = () => {
  const { i18n } = React.useContext(TranslationsContext);
  return (
    <HStack p={3} justifyContent={"space-between"}>
      {/* open link in app-browser */}
      <NavigationFooterPressable url="https://www.instagram.com/piassoai/">
        <NavigationFooterIcon name="logo-instagram" />
      </NavigationFooterPressable>
      <NavigationFooterPressable url="https://piassoxyz.notion.site/Terms-of-Service-452dbc7b6de64bf9b530274858e00faa">
        <NavigationFooterText text={i18n.t("drawer_navigation_footer_terms")} />
      </NavigationFooterPressable>
      <NavigationFooterPressable url="https://piassoxyz.notion.site/Privacy-Policy-68df0c89ba91436396ce4f239986decc">
        <NavigationFooterText
          text={i18n.t("drawer_navigation_footer_privacy")}
        />
      </NavigationFooterPressable>
    </HStack>
  );
};
