import React, { useState } from "react";
import { Dimensions, Platform } from "react-native";
import { Text, Pressable, FlatList, VStack, Select, HStack } from "native-base";
// internal
import { Image } from "src/theme/media/image.component";
import { ScreenFrame } from "src/theme/layout/screen-frame.component";
import { TranslationsContext } from "src/services/translations.context";
import { CopyTextIConButton } from "src/theme/buttons/copy-text-icon-button.component";

export const ImageOnboardingScreen = ({ navigation }) => {
  const options = require("assets/options.json");
  const { i18n } = React.useContext(TranslationsContext);
  const windowWidth = Math.min(Dimensions.get("window")["width"], 992) * 0.9;
  const mainImageSize = Math.min(windowWidth, 400);
  // State variable to track the currently selected option
  const [prefix, setPrefix] = React.useState("a");
  const [base, setBase] = React.useState("1");
  const [suffix, setSuffix] = React.useState("one");
  const prompt =
    options[prefix][`label_${i18n.locale}`] +
    " " +
    options[prefix].options[base][`label_${i18n.locale}`] +
    " " +
    options[prefix].options[base].options[suffix][`label_${i18n.locale}`];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imagesURLPrefix =
    options[prefix].options[base].options[suffix]["images_url_prefix"];
  // Helper function to render the options for a given level
  const renderOptions = (options, value, onValueChange, flex = 1) => (
    <Select
      flex={flex}
      size={"md"}
      variant="filled"
      selectedValue={value}
      onValueChange={onValueChange}
      textAlign={i18n.locale === "ar" ? "right" : "left"}
    >
      {Object.keys(options).map((key) => (
        <Select.Item
          key={key}
          value={key}
          _pressed={{ bg: "transparent" }}
          label={options[key][`label_${i18n.locale}`]}
          alignItems={i18n.locale == "ar" ? "flex-end" : "flex-start"}
        />
      ))}
    </Select>
  );

  return (
    <ScreenFrame
      px={5}
      space={10}
      justifyContent="center"
      onBack={() =>
        Platform.OS === "web"
          ? navigation.navigate("ImageHome")
          : navigation.goBack()
      }
    >
      <VStack space={5}>
        <Text
          fontSize={"2xl"}
          textAlign="center"
          color="primary.600"

        >
          {i18n.t("how_to_screen_title")}
        </Text>
        <Text fontSize={"md"}>{i18n.t("how_to_screen_body")}</Text>
      </VStack>

      <VStack space={5}>
        <HStack
          space={3}
          direction={i18n.locale == "ar" ? "row-reverse" : "row"}
        >
          {renderOptions(options, prefix, setPrefix, 2)}
          {renderOptions(options[prefix].options, base, setBase, 3)}
        </HStack>
        <HStack>
          {renderOptions(
            options[prefix].options[base].options,
            suffix,
            setSuffix
          )}
        </HStack>
        <CopyTextIConButton text={prompt} size="sm" />
      </VStack>

      <Image
        alignSelf="center"
        alt={prompt}
        size={mainImageSize}
        source={{
          uri: `${imagesURLPrefix}${currentImageIndex}.jpg`,
        }}
      />

      <FlatList
        horizontal={true}
        maxHeight={mainImageSize}
        data={[...Array(9).keys()]}
        showsVerticalScrollIndicator={false}
        renderItem={({ item }) => (
          <Pressable key={item} onPress={() => setCurrentImageIndex(item)}>
            <Image
              alt={prompt}
              size={mainImageSize / 3}
              source={{ uri: `${imagesURLPrefix}${item}.jpg` }}
            />
          </Pressable>
        )}
      />

      <Text variant={"caption"} fontSize="2xs">
        {i18n.t("how_to_screen_disclaimer")}
      </Text>
    </ScreenFrame>
  );
};
