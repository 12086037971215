import React from "react";
import { Pressable } from "react-native";
import { HStack, Text, VStack } from "native-base";
import { TranslationsContext } from "src/services/translations.context";

export const ToggleLanguageButtons = () => {
  const { updateLocale } = React.useContext(TranslationsContext);
  return (
    <VStack space={5}>
      <HStack justifyContent="space-evenly">
        <Pressable onPress={() => updateLocale("ar")}>
          <Text>عربي</Text>
        </Pressable>
      </HStack>
      <HStack justifyContent="space-evenly">
        <Pressable onPress={() => updateLocale("en")}>
          <Text>English</Text>
        </Pressable>
        <Pressable onPress={() => updateLocale("de")}>
          <Text>Deutsch</Text>
        </Pressable>
      </HStack>
    </VStack>
  );
};
