import React from "react";
import { IconButton } from "src/theme/buttons/icon-button.component";

export const NavigateToImageListButton = ({ navigation }) => (
  <IconButton
    onPress={() =>
      navigation.navigate("ImageNavigator", {
        screen: "ImageList",
      })
    }
    iconName="images"
  />
);
