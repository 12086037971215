import React from "react";
import { useQuery } from "react-query";
import { doc, getDoc, collection } from "firebase/firestore";
// internal
import { AuthContext } from "src/services/auth.context";
import { FirebaseContext } from "src/services/firebase.context";

export const useChat = (chatId, refetchOnWindowFocus = false) => {
  const { user } = React.useContext(AuthContext);
  const { firestore } = React.useContext(FirebaseContext);

  return useQuery(
    ["chats", chatId],
    () => {
      console.debug(`💬 Chat ${chatId}`);
      return getDoc(
        doc(collection(doc(firestore, "users", user?.uid), "chats"), chatId)
      ).then((doc) => doc.data());
    },
    {
      enabled: !!chatId && !!user,
      onError: (error) => console.error(error),
      refetchOnWindowFocus: refetchOnWindowFocus,
    }
  );
};
