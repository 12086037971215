import React from "react";
import { Button, Toast } from "native-base";
import { AuthContext } from "src/services/auth.context";
import { TranslationsContext } from "src/services/translations.context";
import { useNavigation } from "@react-navigation/native";

export const AuthenticatedButton = ({ children, onPress, ...props }) => {
  const { user } = React.useContext(AuthContext);
  const { i18n } = React.useContext(TranslationsContext);
  const navigation = useNavigation();
  return (
    <Button
      {...props}
      onPress={() => {
        if (!user) {
          Toast.show({
            bg: "primary.600",
            title: i18n.t("user_login_or_register_toast_title"),
            description: i18n.t("user_login_or_register_toast_description"),
          });
          navigation.navigate("UserNavigator", { screen: "UserLogin" });
        } else {
          onPress();
        }
      }}
    >
      {children}
    </Button>
  );
};
