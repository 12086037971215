import React from "react";
import { useInfiniteQuery } from "react-query";
import {
  doc,
  query,
  limit,
  where,
  getDocs,
  orderBy,
  collection,
  startAfter,
} from "firebase/firestore";
import { AuthContext } from "src/services/auth.context";
import { FirebaseContext } from "src/services/firebase.context";

export const useRelatedImage = (imageID, itemsLimit = 6) => {
  const { user } = React.useContext(AuthContext);
  const { firestore } = React.useContext(FirebaseContext);

  return useInfiniteQuery(
    ["images", imageID],
    ({ pageParam }) => {
      const baseQuery = query(
        collection(doc(firestore, "users", user?.uid), "images"),
        where("parent_id", "==", imageID),
        orderBy("created_at", "desc"),
        limit(itemsLimit)
      );
      if (pageParam) {
        console.debug(`🌃 Images ...`);
        return getDocs(query(baseQuery, startAfter(pageParam))).then(
          (snapshot) => snapshot.docs
        );
      } else {
        console.debug(`🌃 Images`);
        return getDocs(baseQuery).then((snapshot) => snapshot.docs);
      }
    },
    {
      enabled: !!user && !!imageID,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      getNextPageParam: (snapshot) =>
        snapshot?.length > 0 ? snapshot[snapshot.length - 1] : undefined,
    }
  );
};
