import React from "react";
import { Dimensions } from "react-native";
import {
  FlatList,
  HStack,
  Spinner,
  useBreakpointValue,
  VStack,
} from "native-base";
import { useNavigation } from "@react-navigation/native";
// internal hooks
import { useRelatedImage } from "../hooks/useRelatedImage";
// internal components
import { ImageListItem } from "../components/image-list-item.component";

const ImageListRelatedFlatList = ({
  images,
  parent,
  itemSize,
  imagesData,
  navigation,
  screenWidth,
  ...props
}) => (
  <HStack width={"100%"}>
    <FlatList
      {...props}
      data={imagesData}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      onEndReached={() =>
        !images.isFetching && images.hasNextPage && images.fetchNextPage()
      }
      ListFooterComponent={
        images.isFetching &&
        !images.isRefetching && <Spinner m={10} key={"spinner"} />
      }
      renderItem={({ item, index }) => (
        <ImageListItem
          key={index}
          image={item}
          size={itemSize}
          onPress={() => {
            navigation.push("ImageDetail", {
              imageID: item?.id,
              parent: parent,
            });
          }}
        />
      )}
    />
  </HStack>
);

export const ImageListRelated = ({ imageID, parent }) => {
  const navigation = useNavigation();
  const images = useRelatedImage(imageID);
  // flatten the list of paginated images
  const imagesData = images.data?.pages
    .flatMap((page) => page)
    .map((doc) => doc.data());
  const drawerType = useBreakpointValue({ base: "slide", lg: "permanent" });
  const screenWidth =
    Dimensions.get("window").width - (drawerType === "slide" ? 0 : 250 + 25);
  return (
    <ImageListRelatedFlatList
      images={images}
      parent={parent}
      horizontal={true}
      imagesData={imagesData}
      navigation={navigation}
      screenWidth={screenWidth}
      itemSize={screenWidth / 4.5}
    />
  );
};
