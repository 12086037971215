import React from "react";
import { Toast } from "native-base";
import { useMutation, useQueryClient } from "react-query";
// internal
import { AxiosContext } from "src/services/axios.context";
import { updateCachedChatData } from "src/utils/updateCachedChatData";
import { TranslationsContext } from "src/services/translations.context";
import { Platform } from "react-native";
export const ChatsContext = React.createContext(null);

export const ChatsContextProvider = ({ children }) => {
  // Persona Related Setup
  const isWeb = Platform.OS === "web";
  const defaultPersona = {
    prompt: "",
    id: "default",
    name: "Default",
    icon_name: "infinite",
  };
  const [selectedPersona, setSelectedPersona] = React.useState(defaultPersona);
  // Chat Related Setup
  const queryClient = useQueryClient();
  const websocketRef = React.useRef(null);
  const { client } = React.useContext(AxiosContext);
  const { i18n } = React.useContext(TranslationsContext);
  const [isStreaming, setIsStreaming] = React.useState(false);

  const handleError = (error) => {
    Toast.show({
      bg: "danger.900",
      title: i18n.t("general_error_toast_title"),
      description:
        error.response?.status === 402
          ? i18n.t(error?.response?.data?.detail)
          : i18n.t("general_error_toast_description"),
    });
  };

  const stopChat = () => {
    console.log("Stopping Chat");
    if (websocketRef.current) {
      websocketRef.current.close();
      websocketRef.current = null;
    }
  };

  const deleteChat = useMutation(
    (chatId) => {
      return client.delete(`chats/${chatId}/`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("chats");
      },
    }
  );

  const updateChat = async (chatId, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const baseURL = client.defaults.baseURL.replace(/^http/, "ws");
        const token = client.defaults.headers.common.Authorization.replace(
          "Bearer ",
          ""
        );
        const url = `${baseURL}chats/${chatId}/?token=${token}`;
        const websocket = new WebSocket(url);

        websocket.onopen = () => {
          setIsStreaming(true);
          updateCachedChatData(
            queryClient,
            chatId,
            payload?.content,
            "user",
            true
          );
          websocket.send(JSON.stringify(payload));
        };

        let isNewMessage = true;

        websocket.onclose = () => {
          setIsStreaming(false);
          queryClient.invalidateQueries(["chats", chatId]);
          resolve();
        };

        let chunks = [];
        websocket.onmessage = (event) => {
          const chunk = event.data;
          chunks.push(chunk);

          if (chunks?.length % 5 === 0 || isWeb) {
            const chunk = chunks.join("");
            chunks = [];
            updateCachedChatData(
              queryClient,
              chatId,
              chunk,
              "assistant",
              isNewMessage
            );
            isNewMessage = false;
          }
        };

        websocket.onerror = (error) => {
          console.error("Error while streaming:", error);
        };

        websocketRef.current = websocket;
      } catch (error) {
        handleError(error);
        reject(error);
      }
    });
  };

  const createChat = async () => {
    try {
      setIsStreaming(true);
      const response = await client.post(`chats/`);
      return response?.data;
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <ChatsContext.Provider
      value={{
        stopChat,
        updateChat,
        createChat,
        deleteChat,
        isStreaming,
        // Persona Related
        defaultPersona,
        selectedPersona,
        setSelectedPersona,
      }}
    >
      {children}
    </ChatsContext.Provider>
  );
};
