export const updateCachedChatData = (
  queryClient,
  chatId,
  content,
  role,
  isNewMessage
) => {
  // Update the cached chat data with the updated messages array
  queryClient.setQueryData(["chats", chatId], (currentChatData = {}) => {
    // Get the current chat data or initialize an empty object if it doesn't exist
    let updatedMessages = currentChatData.messages || [];

    if (isNewMessage) {
      updatedMessages.push({ content: content, role: role });
    } else {
      const lastMessageIndex = updatedMessages.length - 1;
      updatedMessages[lastMessageIndex].content += content;
    }

    return {
      ...currentChatData,
      messages: updatedMessages,
    };
  });
};
