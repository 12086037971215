import React from "react";
import { Box, Image as NBImage, Skeleton, Spinner } from "native-base";

export const Image = ({ alt = "default", source, size, ...props }) => {
  const [imageIsLoading, setImageIsLoading] = React.useState(true);
  return (
    <>
      {imageIsLoading && (
        <Box
          justifyContent={"center"}
          alignItems="center"
          height={size}
          width={size}
          position={"absolute"}
        >
          <Spinner />
        </Box>
      )}
      <NBImage
        alt={alt}
        width={size}
        height={size}
        source={source}
        maxWidth={"100%"}
        onLoadEnd={() => setImageIsLoading(false)}
        {...props}
      />
    </>
  );
};
