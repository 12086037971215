import React from "react";
import { useForm } from "react-hook-form";
import { Keyboard, Platform } from "react-native";
import { Ionicons, Entypo } from "@expo/vector-icons";
import {
  Box,
  Icon,
  VStack,
  Button,
  HStack,
  IconButton,
  KeyboardAvoidingView,
} from "native-base";
// internal services
import { TranslationsContext } from "src/services/translations.context";
// internal hooks
import { TextArea } from "src/theme/forms/text-area.component";
import { ImageCreateButton } from "./image-create-button.component";
import { ImageCreateSettings } from "./image-create-settings.component";
import { ImageCreateYamliTextArea } from "./image-create-yamli-text-area.component";

export const ImageCreate = React.memo(({ maxH = 75, initialImage = null }) => {
  const { control, handleSubmit, setValue } = useForm();

  const [showStyles, setShowStyles] = React.useState(false);
  const [keyboardOpen, setKeyboardOpen] = React.useState(false);
  const { i18n, isYamliEnabled } = React.useContext(TranslationsContext);

  React.useEffect(() => {
    const keyboardWillShowListener = Keyboard.addListener(
      "keyboardWillShow",
      () => setKeyboardOpen(true)
    );
    const keyboardWillHideListener = Keyboard.addListener(
      "keyboardWillHide",
      () => setKeyboardOpen(false)
    );

    return () => {
      keyboardWillShowListener.remove();
      keyboardWillHideListener.remove();
    };
  }, []);
  React.useEffect(() => {
    if (initialImage) {
      setValue("model", initialImage?.model);
      setValue("prompt", initialImage?.prompt);
    }
    setValue("settings", initialImage?.settings ? initialImage?.settings : {});
  }, [initialImage]);

  return (
    <KeyboardAvoidingView
      width={"100%"}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <Box borderTopRadius={"2xl"} overflow={"hidden"}>
        <VStack
          px={3}
          pt={3}
          space={3}
          borderRadius={0}
          pb={keyboardOpen || Platform.OS === "web" ? 3 : 0}
          _dark={{ bg: "darkBackground" }}
          _light={{ bg: "lightBackground" }}
          safeAreaBottom={!keyboardOpen ? true : undefined}
        >
          {showStyles && <ImageCreateSettings control={control} />}
          <HStack space={3} justifyContent={"center"} alignItems="center">
            {isYamliEnabled ? (
              <ImageCreateYamliTextArea
                name="prompt"
                control={control}
                // logic
                h={"auto"}
                maxLength={480}
                value={initialImage?.prompt}
                maxH={keyboardOpen ? 150 : maxH}
                // rules
                rules={{ maxLength: 480, minLength: 1, required: true }}
                // styling
                placeholder={i18n.t("image_create_input_placeholder")}
              />
            ) : (
              <TextArea
                h={"auto"}
                name="prompt"
                control={control}
                autoFocus={true}
                maxLength={480}
                value={initialImage?.prompt}
                maxH={keyboardOpen ? 150 : maxH}
                // rules
                rules={{ maxLength: 480, minLength: 1, required: true }}
                // styling
                placeholder={i18n.t("image_create_input_placeholder")}
              />
            )}
          </HStack>
          <HStack alignItems={"center"} justifyContent="space-between">
            <HStack flex={1}>
              <Button
                size="sm"
                variant={"ghost"}
                colorScheme={"primary"}
                _text={{
                  fontSize: "sm",
                }}
                isPressed={showStyles}
                rightIcon={<Icon as={Ionicons} name="color-palette" />}
                onPress={() => setShowStyles(!showStyles)}
              >
                {i18n.t("image_create_settings_button")}
              </Button>
            </HStack>

            <ImageCreateButton handleSubmit={handleSubmit} />
            <HStack flex={1} justifyContent="flex-end">
              {keyboardOpen && (
                <IconButton
                  colorScheme={"primary"}
                  onPress={Keyboard.dismiss}
                  icon={
                    <VStack alignItems={"center"}>
                      <Icon
                        size={"md"}
                        as={Entypo}
                        name="keyboard"
                        _dark={{
                          color: "primary.600",
                        }}
                        _light={{
                          color: "primary.600",
                        }}
                      />
                      <Icon
                        size={"xs"}
                        as={Ionicons}
                        name="caret-down"
                        _dark={{
                          color: "primary.600",
                        }}
                        _light={{
                          color: "primary.600",
                        }}
                      />
                    </VStack>
                  }
                />
              )}
            </HStack>
          </HStack>
        </VStack>
      </Box>
    </KeyboardAvoidingView>
  );
});
