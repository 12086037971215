import React from "react";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth, initializeAuth, Auth } from "firebase/auth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getReactNativePersistence } from "firebase/auth/react-native";

export const FirebaseContext = React.createContext(null);

export const FirebaseContextProvider = ({ children }) => {
  const firebaseConfig = {
    apiKey: "AIzaSyC_7LoDC7hVxrB3agHyKCkv6CHU0KDH1KA",
    authDomain: "piasso.firebaseapp.com",
    projectId: "piasso",
    storageBucket: "piasso.appspot.com",
    messagingSenderId: "678034785342",
    appId: "1:678034785342:web:bcc6002697c452b18b7d6f",
    measurementId: "G-EKQT0N93K7",
  };

  const app = initializeApp(firebaseConfig);
  const firestore = getFirestore(app);
  const storage = getStorage(app);
  let auth;
  try {
    auth = initializeAuth(app, {
      persistence: getReactNativePersistence(AsyncStorage),
    });
  } catch (error) {
    auth = getAuth(app);
  }

  console.debug("🔥 Auth + Firestore + Storage");

  return (
    <FirebaseContext.Provider
      value={{
        app,
        auth,
        storage,
        firestore,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
