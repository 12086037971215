import React from "react";
import { Button, Modal } from "native-base";
import { useNavigation } from "@react-navigation/native";
import { IconButton } from "src/theme/buttons/icon-button.component";
// internal hooks
import { useDeleteImage } from "../hooks/useDeleteImage";
// internal components
import { TranslationsContext } from "src/services/translations.context";

export const ImageDetailDeleteButton = ({ image }) => {
  const navigation = useNavigation();

  const deleteImage = useDeleteImage(image?.id);
  const { i18n } = React.useContext(TranslationsContext);
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false);

  React.useEffect(() => {
    if (deleteImage.isSuccess) {
      navigation.navigate("ImageList");
    }
  }, [deleteImage.isSuccess]);
  return (
    <>
      <Modal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>{i18n.t("image_delete_button")}</Modal.Header>
          <Modal.Body>{i18n.t("image_delete_confirmation")}</Modal.Body>
          <Modal.Footer>
            <Button.Group px={5} space={2}>
              <Button
                px={5}
                variant={"solid"}
                onPress={() => {
                  setShowConfirmationModal(false);
                }}
              >
                {i18n.t("general_no_button")}
              </Button>
              <Button
                variant="ghost"
                colorScheme="danger"
                onPress={() => {
                  deleteImage.mutate(image.id);
                  setShowConfirmationModal(false);
                }}
              >
                {i18n.t("general_yes_button")}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <IconButton
        iconName="trash"
        colorScheme="danger"
        onPress={() => {
          setShowConfirmationModal(true);
        }}
      />
    </>
  );
};
