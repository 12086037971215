import React from "react";
import { Toast } from "native-base";
import { useMutation, useQueryClient } from "react-query";
// internal
import { AxiosContext } from "src/services/axios.context";
import { TranslationsContext } from "src/services/translations.context";

export const useCreateImage = (n = 1) => {
  const { client } = React.useContext(AxiosContext);
  const { i18n } = React.useContext(TranslationsContext);

  const queryClient = useQueryClient();

  return useMutation(
    (payload) => {
      console.debug(
        `🌃 Create Image Authorized: ${
          client?.defaults?.headers?.common?.Authorization != null
        }`
      );
      return client.post(`images/`, payload);
    },
    {
      onSuccess: (resp) => {
        Toast.show({
          placement: "top",
          bg: "primary.600",
          title: i18n.t("image_create_success_toast_title"),
        });
        queryClient.refetchQueries(["images"]);
      },
      onError: (error) => {
        console.error(error.response?.data);
        Toast.show({
          duration: 5000,
          bg: "danger.900",
          title: i18n.t("general_error_toast_title"),
          description:
            error?.response?.status === 402
              ? i18n.t(error?.response?.data?.detail)
              : i18n.t("image_create_error_toast_description"),
        });
      },
    }
  );
};
