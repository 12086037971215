import React from "react";
import { Ionicons } from "@expo/vector-icons";
import { useController } from "react-hook-form";
import {
  Icon,
  VStack,
  Pressable,
  FormControl,
  TextArea as NBTextArea,
} from "native-base";

export const TextArea = ({
  name,
  control,
  rules = {},
  label = null,
  value = null,
  ...props
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: rules,
    defaultValue: value,
  });

  return (
    <FormControl isInvalid={!!error}>
      <VStack space={5}>
        {label && (
          <FormControl.Label _text={{ fontSize: "md"}}>
            {label}
          </FormControl.Label>
        )}
        <NBTextArea
          {...props}
          borderWidth={1}
          isInvalid={!!error}
          value={field.value}
          variant="filled"
          onChangeText={field.onChange}
          isRequired={!!rules?.required}
          InputRightElement={
            field.value && (
              <Pressable p={1} onPress={() => field.onChange("")}>
                <Icon size="md" as={Ionicons} name="close-circle" />
              </Pressable>
            )
          }
        />
      </VStack>
    </FormControl>
  );
};
