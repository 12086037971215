import React from "react";
import { Ionicons } from "@expo/vector-icons";
import { Button, HStack, Icon, Pressable, Text } from "native-base";
import { TranslationsContext } from "src/services/translations.context";

export const NavigateToChatListButton = ({ navigation }) => {
  const { i18n } = React.useContext(TranslationsContext);
  return (
    <Button
      py={1}
      bg={"primary.400"}
      borderRadius={"md"}
      onPress={() =>
        navigation.navigate("ChatNavigator", {
          screen: "ChatList",
        })
      }
      leftIcon={<Icon as={Ionicons} color="gray.50" name="chatbubbles" />}
    >
      {i18n.t("chat_history_button")}
    </Button>
  );
};
