import React from "react";
import { useForm } from "react-hook-form";
import { Ionicons } from "@expo/vector-icons";
import { Icon, Text, HStack, Pressable } from "native-base";
// internal
import { PersonaForm } from "./persona-form.component";
import { useCreatePersona } from "../hooks/useCreatePersona";
import { TranslationsContext } from "src/services/translations.context";

export const PersonaCreate = () => {
  const createPersona = useCreatePersona();
  const { control, handleSubmit } = useForm();
  const { i18n } = React.useContext(TranslationsContext);
  const [icon, setIcon] = React.useState("sad");
  const [showModal, setShowModal] = React.useState(false);

  const onPress = (data) => {
    const payload = {
      ...data,
      icon,
    };
    createPersona.mutate(payload);
    setShowModal(false);
  };

  return (
    <>
      <PersonaForm
        icon={icon}
        control={control}
        setIcon={setIcon}
        showModal={showModal}
        setShowModal={setShowModal}
        onSubmit={handleSubmit(onPress)}
      />
      <Pressable
        m={1}
        p={2}
        bg={"gray.50"}
        borderRadius={"md"}
        _dark={{
          bg: "gray.900",
          _pressed: {
            bg: "gray.800",
          },
        }}
        _light={{
          shadow: 1,
          bg: "gray.50",
          _pressed: {
            shadow: 2,
            bg: "gray.100",
          },
        }}
        onPress={() => setShowModal(true)}
      >
        <HStack space="xs" alignItems={"center"}>
          <Icon
            size="sm"
            as={Ionicons}
            name="add-circle"
            _dark={{
              color: "lightText",
            }}
            _light={{
              color: "darkText",
            }}
          />
          <Text textAlign={"center"} fontSize={"xs"} width={60} noOfLines={2}>
            {i18n.t("persona_create_button")}
          </Text>
        </HStack>
      </Pressable>
    </>
  );
};
