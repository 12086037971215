import React from "react";
import { Ionicons } from "@expo/vector-icons";
import { Icon, Spinner, Pressable } from "native-base";

export const IconButton = ({
  onPress,
  iconName,
  size = "lg",
  as = Ionicons,
  isLoading = false,
  isDisabled = false,
  colorScheme = "primary",
  borderRadius = undefined,
  background = "transparent",
  ...props
}) => (
  <Pressable
    p={3}
    onPress={onPress}
    background={background}
    borderRadius={borderRadius}
    isDisabled={isDisabled || isLoading}
  >
    {isLoading ? (
      <Spinner
        _light={{
          color: colorScheme + ".600",
        }}
        _dark={{
          color: colorScheme + ".300",
        }}
      />
    ) : (
      <Icon
        as={as}
        size={size}
        name={iconName}
        _light={{
          color: colorScheme + ".600",
        }}
        _dark={{
          color: colorScheme + ".800",
        }}
      />
    )}
  </Pressable>
);
