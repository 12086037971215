import React from "react";
import { HStack, Switch, Text, VStack } from "native-base";
import { TranslationsContext } from "src/services/translations.context";

export const ToggleYamli = () => {
  const { isYamliEnabled, setIsYamliEnabled } =
    React.useContext(TranslationsContext);
  return (
    <HStack px={3} space={5} alignItems="center" justifyContent={"center"}>
      <Switch
        size={"sm"}
        onValueChange={setIsYamliEnabled}
        value={!!isYamliEnabled ? true : false}
        isChecked={!!isYamliEnabled ? true : false}
      />
      <VStack>
        <Text  fontSize="sm">
          Franco Arabic
        </Text>
        <Text fontSize={"2xs"} textAlign={"left"}>
          powered by{" "}
          <Text color={"purple.600"} >
            Yamli
          </Text>
        </Text>
      </VStack>
    </HStack>
  );
};
