export const appRoutesConfig = {
  screens: {
    ChatNavigator: {
      screens: {
        ChatList: "chats/",
        ChatHome: "chats/home/",
        ChatDetail: "chats/:chatId",
      },
    },
    ImageNavigator: {
      screens: {
        ImageList: "images/",
        ImageHome: "images/home/",
        ImageDetail: "images/:imageID/",
        ImageOnboarding: "images/onboarding/",
      },
    },
    UserNavigator: {
      screens: {
        UserDetail: "account/",
        UserLogin: "account/login/",
        UserRegister: "account/register/",
        UserForgotPassword: "account/forgot-password/",
      },
    },
  },
};
