import React from "react";
import { useBreakpointValue } from "native-base";
import { Dimensions, Platform } from "react-native";
// internal
import { ImageSearch } from "../components/image-search.component";
import { ScreenFrame } from "src/theme/layout/screen-frame.component";
import { ImageList } from "src/features/images/components/image-list.component";
import { ImageListItem } from "src/features/images/components/image-list-item.component";

export const ImageListScreen = ({ navigation }) => {
  const numColumns = useBreakpointValue([4, 5, 6]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const drawerType = useBreakpointValue({ base: "slide", lg: "permanent" });
  const screenWidth =
    Dimensions.get("window").width - (drawerType === "slide" ? 0 : 250 + 25);
  return (
    <ScreenFrame
      px={[0, 0, 0, 3]}
      safeAreaBottom={0}
      withScrollView={false}
      onBack={() =>
        Platform.OS === "web"
          ? navigation.navigate("ImageHome")
          : navigation.goBack()
      }
    >
      <ImageSearch setSearchTerm={setSearchTerm} searchTerm={searchTerm} />
      {!searchTerm?.query && (
        <ImageList
          itemsLimit={15}
          windowSize={15}
          pagingEnabled={false}
          createdAt={new Date(0)}
          numColumns={numColumns}
          initialNumToRender={15}
          itemSize={screenWidth / numColumns}
          renderItem={({ item, index }) => (
            <ImageListItem
              key={index}
              image={item}
              size={screenWidth / numColumns}
              onPress={() => {
                navigation.navigate("ImageDetail", {
                  imageID: item?.id,
                  parent: "ImageList",
                });
              }}
            />
          )}
        />
      )}
    </ScreenFrame>
  );
};
