import React from "react";
import { FlatList, Spinner, VStack } from "native-base";
// internal hooks
import { useImages } from "src/features/images/hooks/useImages";
import { ImageListExamples } from "./image-list-examples.component";

export const ImageList = ({
  itemSize,
  itemsLimit,
  horizontal,
  windowSize,
  renderItem,
  numColumns,
  pagingEnabled,
  initialImageID,
  setSelectedImage,
  initialNumToRender,
  setSelectedImageOnMomentumScrollEnd,
  createdAt = undefined,
  showExamplesIfEmpty = false,
  ...props
}) => {
  const images = useImages(createdAt, itemsLimit);
  // initialise flatList ref
  const flatListRef = React.useRef();
  // flatten the list of paginated images
  const imagesData = images.data?.pages
    .flatMap((page) => page)
    .map((doc) => doc.data());
  // find initialScrollIndex
  const initialScrollIndex =
    initialImageID && imagesData?.length > 0
      ? imagesData?.findIndex((imageData) => imageData?.id === initialImageID)
      : 0;

  React.useEffect(() => {
    if (initialImageID && flatListRef?.current && imagesData?.length > 0) {
      flatListRef?.current?.scrollToIndex({
        index: imagesData?.findIndex(
          (imageData) => imageData?.id === initialImageID
        ),
      });
    }
  }, [initialImageID]);

  return (
    <FlatList
      {...props}
      ref={flatListRef}
      data={imagesData}
      numColumns={numColumns}
      windowSize={windowSize}
      horizontal={horizontal}
      renderItem={renderItem}
      onEndReachedThreshold={0.1}
      pagingEnabled={pagingEnabled}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      initialNumToRender={initialNumToRender}
      initialScrollIndex={initialScrollIndex}
      onEndReached={() =>
        !images.isFetching && images.hasNextPage && images.fetchNextPage()
      }
      getItemLayout={(data, index) => ({
        length: itemSize,
        offset: itemSize * index,
        index,
      })}
      onMomentumScrollEnd={({ nativeEvent }) => {
        if (setSelectedImageOnMomentumScrollEnd && setSelectedImage) {
          const contentOffset = nativeEvent.contentOffset;
          const currentIndex = Math.floor(contentOffset.x / itemSize);
          const currentItem = imagesData[currentIndex];
          setSelectedImage(currentItem);
        }
      }}
      ListFooterComponent={
        <VStack>
          {images.isFetching && !images.isRefetching && (
            <Spinner m={10} key={"spinner"} />
          )}
          {showExamplesIfEmpty && !images.hasNextPage && !images.isLoading && (
            <ImageListExamples
              windowSize={40}
              key={"examples"}
              itemSize={itemSize}
              horizontal={horizontal}
              numColumns={numColumns}
              initialNumToRender={40}
              pagingEnabled={pagingEnabled}
            />
          )}
        </VStack>
      }
    />
  );
};
