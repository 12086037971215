export const themeConfig = (locale = "en") => {
  return {
    useSystemColorMode: true,
    initialColorMode: "light",
    components: {
      // Infra
      Skeleton: {
        defaultProps: {
          borderRadius: 0,
          _dark: {
            endColor: "black",
            startColor: "gray.900",
          },
        },
      },
      Divider: {
        baseStyle: {
          _dark: {
            bg: "gray.900",
          },
          _light: {
            bg: "gray.100",
          },
        },
      },
      Toast: {
        defaultProps: {
          mx: 5,
        },
      },
      Text: {
        defaultProps: {
          fontWeight: "medium",
          textAlign: locale == "ar" ? "right" : "left",
        },
      },
      // Media
      Image: {
        defaultProps: {
          alt: "image",
          borderWidth: 2,
          borderRadius: "lg",
          _dark: {
            borderColor: "darkBackground",
          },
          _light: {
            borderColor: "lightBackground",
          },
        },
      },

      // Input
      TextArea: {
        defaultProps: {
          size: "lg",
          shadow: 3,
          borderWidth: 0,
          variant: "unstyled",
          borderRadius: "lg",
          textAlign: locale == "ar" ? "right" : "left",
          _hover: {
            shadow: 5,
          },
          _focus: {
            shadow: 5,
          },
          _dark: {
            bg: "gray.900",
            color: "lightText",
          },
        },
      },
      Input: {
        defaultProps: {
          borderWidth: 0,
          borderRadius: "lg",
          variant: "unstyled",
          borderColor: "transparent",
          fontWeight: "medium",
          textAlign: locale == "ar" ? "right" : "left",
          _light: {
            _web: {
              shadow: 0,
              _focus: {
                shadow: 3,
              },
              _hover: {
                shadow: 2,
              },
            },
            _ios: {
              bg: "gray.100",
              _hover: {
                bg: "gray.50",
              },
              _focus: {
                bg: "gray.50",
              },
            },
          },
          _dark: {
            bg: "gray.900",
          },
        },
      },
      Button: {
        defaultProps: {
          borderRadius: "3xl",
          _text: {

            textAlign: locale == "ar" ? "right" : "left",
          },
        },
      },
    },

    colors: {
      lightBackground: "#FDFDFD",
      darkBackground: "#131219",
      gray: {
        50: "#fafafa",
        100: "#f4f4f5",
        200: "#e4e4e7",
        300: "#d4d4d8",
        400: "#d4d4d8",
        500: "#9693a4",
        600: "#6e6b7b",
        700: "#5a5767",
        800: "#3c3948",
        900: "#1b1926",
      },
      primary: {
        50: "#e1f1f1",
        100: "#b4dddc",
        200: "#85c7c6",
        300: "#58b1af",
        400: "#3ca09d",
        500: "#2d8f8a",
        600: "#29837d",
        700: "#26736d",
        800: "#24635d",
        900: "#1b4741",
      },
      secondary: {
        50: "#f9eae5",
        100: "#f1cbbf",
        200: "#e8a994",
        300: "#de8669",
        400: "#d76c48",
        500: "#d05228",
        600: "#cb4b24",
        700: "#c4411e",
        800: "#be3818",
        900: "#b3280f",
      },
      tertiary: {
        50: "#e8eaf6",
        100: "#c5cbe9",
        200: "#9fa8da",
        300: "#7985cb",
        400: "#5c6bc0",
        500: "#3f51b5",
        600: "#394aae",
        700: "#3140a5",
        800: "#29379d",
        900: "#1b278d",
      },
      white: {
        50: "#ffffff",
        100: "#ffffff",
        200: "#ffffff",
        300: "#ffffff",
        400: "#ffffff",
        500: "#ffffff",
        600: "#ffffff",
        700: "#ffffff",
        800: "#ffffff",
        900: "#ffffff",
      },
      danger: {
        50: "#f5e5e7",
        100: "#e7bfc2",
        200: "#c78582",
        300: "#ad5955",
        400: "#ad3831",
        500: "#a82416",
        600: "#9b1c16",
        700: "#8c1312",
        800: "#7f0d0c",
        900: "#700600",
      },
      error: {
        50: "#f5e5e7",
        100: "#e7bfc2",
        200: "#c78582",
        300: "#ad5955",
        400: "#ad3831",
        500: "#a82416",
        600: "#9b1c16",
        700: "#8c1312",
        800: "#7f0d0c",
        900: "#700600",
      },
      success: {
        50: "#e1f1f1",
        100: "#b4dddc",
        200: "#85c7c6",
        300: "#58b1af",
        400: "#3ca09d",
        500: "#2d8f8a",
        600: "#29837d",
        700: "#26736d",
        800: "#24635d",
        900: "#1b4741",
      },
      warning: {
        50: "#f9eae5",
        100: "#f1cbbf",
        200: "#e8a994",
        300: "#de8669",
        400: "#d76c48",
        500: "#d05228",
        600: "#cb4b24",
        700: "#c4411e",
        800: "#be3818",
        900: "#b3280f",
      },
      info: {
        50: "#e8eaf6",
        100: "#c5cbe9",
        200: "#9fa8da",
        300: "#7985cb",
        400: "#5c6bc0",
        500: "#3f51b5",
        600: "#394aae",
        700: "#3140a5",
        800: "#29379d",
        900: "#1b278d",
      },
    },
    fontConfig: {
      Caveat: {
        100: {
          normal: "Caveat_400Regular",
        },
        200: {
          normal: "Caveat_400Regular",
        },
        300: {
          normal: "Caveat_400Regular",
        },
        400: {
          normal: "Caveat_400Regular",
        },
        500: {
          normal: "Caveat_500Medium",
        },
        600: {
          normal: "Caveat_600SemiBold",
        },
        700: {
          normal: "Caveat_700Bold",
        },
        800: {
          normal: "Caveat_700Bold",
        },
        900: {
          normal: "Caveat_700Bold",
        },
      },
      NotoSans: {
        100: {
          normal: "NotoSansArabic_100Thin",
        },
        200: {
          normal: "NotoSansArabic_200ExtraLight",
        },
        300: {
          normal: "NotoSansArabic_300Light",
        },
        400: {
          normal: "NotoSansArabic_400Regular",
        },
        500: {
          normal: "NotoSansArabic_500Medium",
        },
        600: {
          normal: "NotoSansArabic_600SemiBold",
        },
        700: {
          normal: "NotoSansArabic_700Bold",
        },
        800: {
          normal: "NotoSansArabic_800ExtraBold",
        },
        900: {
          normal: "NotoSansArabic_900Black",
        },
      },
      Roboto: {
        100: {
          normal: "Roboto_100Thin",
          italic: "Roboto_100Thin_Italic",
        },
        300: {
          normal: "Roboto_300Light",
          italic: "Roboto_300Light_Italic",
        },
        400: {
          normal: "Roboto_400Regular",
          italic: "Roboto_400Regular_Italic",
        },
        500: {
          normal: "Roboto_500Medium",
          italic: "Roboto_500Medium_Italic",
        },
        700: {
          normal: "Roboto_700Bold",
          italic: "Roboto_700Bold_Italic",
        },
        900: {
          normal: "Roboto_900Black",
          italic: "Roboto_900Black_Italic",
        },
      },
      SignikaNegative: {
        100: {
          normal: "SignikaNegative_300Light",
        },
        200: {
          normal: "SignikaNegative_300Light",
        },
        300: {
          normal: "SignikaNegative_300Light",
        },
        400: {
          normal: "SignikaNegative_400Regular",
        },
        500: {
          normal: "SignikaNegative_400Regular",
        },
        600: {
          normal: "SignikaNegative_600SemiBold",
        },
        700: {
          normal: "SignikaNegative_700Bold",
        },
        800: {
          normal: "SignikaNegative_700Bold",
        },
        900: {
          normal: "SignikaNegative_700Bold",
        },
      },
    },
    fonts: {
      caveat: "Caveat",
      notoSans: "NotoSans",
      heading: "Roboto",
      body: "Roboto",
      mono: "Roboto",
    },
  };
};
