import React from "react";
import { useQuery } from "react-query";
import { doc, getDoc, collection } from "firebase/firestore";
// internal
import { AuthContext } from "src/services/auth.context";
import { FirebaseContext } from "src/services/firebase.context";

export const useImage = (imageID) => {
  const { user } = React.useContext(AuthContext);
  const { firestore } = React.useContext(FirebaseContext);

  return useQuery(
    ["image", imageID],
    () => {
      console.debug(`💬 Image ${imageID}`);
      return getDoc(
        doc(collection(doc(firestore, "users", user?.uid), "images"), imageID)
      ).then((doc) => doc.data());
    },
    {
      onError: (error) => console.error(error),
      enabled: !!imageID,
    }
  );
};
