import { LogBox } from "react-native";
LogBox.ignoreLogs([
  "We can not support a function callback. See Github Issues for details https://github.com/adobe/react-spectrum/issues/2320",
  "AsyncStorage has been extracted from react-native core and will be removed in a future release. It can now be installed and imported from '@react-native-async-storage/async-storage' instead of 'react-native'. See https://github.com/react-native-async-storage/async-storage",
]);
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
// internal
import { Navigation } from "src/navigation/navigation";
import { FontsProvider } from "src/theme/fonts.provider";
import { ThemeProvider } from "src/theme/theme.provider";
import { AuthContextProvider } from "src/services/auth.context";
import { ChatsContextProvider } from "src/services/chats.context";
import { AxiosContextProvider } from "src/services/axios.context";
import { KeyboardContextProvider } from "src/services/keyboard.context";
import { FirebaseContextProvider } from "src/services/firebase.context";
import { TranslationsContextProvider } from "src/services/translations.context";
import { NotificationsContextProvider } from "src/services/notifications.context";

// Initialise Query Client
const queryClient = new QueryClient();

export default function App() {
  console.debug("🚀----🚀----🚀----🚀----🚀----🚀----🚀");
  return (
    <QueryClientProvider client={queryClient}>
      {/* Translation doesn't need anything */}
      <TranslationsContextProvider>
        {/* Fonts might need translation if we decide to load language-specific fonts */}
        <FontsProvider>
          {/* Theme needs Fonts + Translation */}
          <ThemeProvider>
            {/* Firebase doesn't need anything */}
            <FirebaseContextProvider>
              {/* Auth needs Firebase + Translation */}
              <AuthContextProvider>
                {/* Axios needs Auth */}
                <AxiosContextProvider>
                  {/* Notifications needs Axios + Auth */}
                  <NotificationsContextProvider>
                    <ChatsContextProvider>
                      <KeyboardContextProvider>
                        <Navigation />
                      </KeyboardContextProvider>
                    </ChatsContextProvider>
                  </NotificationsContextProvider>
                </AxiosContextProvider>
              </AuthContextProvider>
            </FirebaseContextProvider>
          </ThemeProvider>
        </FontsProvider>
      </TranslationsContextProvider>
    </QueryClientProvider>
  );
}
