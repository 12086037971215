import React from "react";
import * as Clipboard from "expo-clipboard";
import { Ionicons } from "@expo/vector-icons";
import {
  Box,
  HStack,
  Icon,
  useColorMode,
  IconButton,
  VStack,
  Toast,
} from "native-base";
// internal
import { Markdown } from "src/theme/media/markdown.component";
import { TranslationsContext } from "src/services/translations.context";

function isArabic(text) {
  const arabicRegex = /[\u0600-\u06FF]/;
  return arabicRegex.test(text);
}

export const Message = ({ message, index }) => {
  const { colorMode } = useColorMode();
  const isUser = message?.role === "user";
  const baseColor = isUser ? "primary" : "tertiary";
  const { i18n } = React.useContext(TranslationsContext);

  if (message?.role != "system") {
    const textAlign = isArabic(message?.content) ? "right" : "left";
    return (
      <HStack
        p={5}
        space={5}
        key={index}
        justifyContent={"space-between"}
        _light={{
          bg: isUser ? "lightBackground" : "gray.100",
        }}
        _dark={{
          bg: isUser ? "darkBackground" : "gray.900",
        }}
      >
        <Box>
          <Box
            p={2}
            mt={1}
            borderRadius={"md"}
            _light={{
              bg: `${baseColor}.100`,
            }}
            _dark={{
              bg: `${baseColor}.300`,
            }}
          >
            <Icon
              as={Ionicons}
              _dark={{
                color: "darkBackground",
              }}
              _light={{
                color: "lightBackground",
              }}
              name={isUser ? "person" : "chatbox-ellipses"}
            />
          </Box>
        </Box>
        <Box flex={1}>
          <Markdown
            textAlign={textAlign}
            text={message?.content}
            color={colorMode === "light" ? "black" : "white"}
            />
        </Box>
        <IconButton
          alignSelf={"flex-end"}
          size={"xs"}
          variant={"link"}
          borderRadius={"full"}
          onPress={async () => {
            await Clipboard.setStringAsync(message?.content);
            Toast.show({
              placement: "top",
              bg: "primary.600",
              title: i18n.t("general_copy_success_toast_title"),
            });
          }}
          icon={
            <Icon
              name="copy"
              as={Ionicons}
              _light={{
                color: `${baseColor}.100`,
              }}
              _dark={{
                color: `${baseColor}.300`,
              }}
            />
          }
        />
      </HStack>
    );
  }
};
