import React from "react";
import "firebaseui/dist/firebaseui.css";
import * as firebaseui from "firebaseui";
import firebase from "firebase/compat/app";
import { FirebaseContext } from "src/services/firebase.context";

export const UserLogin = () => {
  const { auth } = React.useContext(FirebaseContext);
  React.useEffect(() => {
    const uiConfig = {
      signInSuccessUrl: "/",
      signInOptions: [
        // firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
    };
    const ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

    ui.start("#firebaseui-auth-container", uiConfig);
  }, []);
  return <div id="firebaseui-auth-container"></div>;
};
