import React from "react";
import PropTypes from "prop-types";
import { connectHighlight } from "react-instantsearch-native";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import { useNavigation } from "@react-navigation/native";

const Highlight = ({ value, isHighlighted }) => {
  const maxLength = 25;
  value =
    value.length > maxLength
      ? value.slice(0, 25) + "..." + value.slice(-25)
      : value;
  return (
    <Text
      _dark={{
        color: isHighlighted ? "lightText" : undefined,
        bg: isHighlighted ? "primary.600" : undefined,
      }}
      _light={{
        color: isHighlighted ? "darkText" : undefined,
        bg: isHighlighted ? "primary.100" : undefined,
      }}
    >
      {value}
    </Text>
  );
};

const ChatSearchResultListItem = ({ attribute, hit, highlight }) => {
  const navigation = useNavigation();
  // iterate n times n being the length of hit?.messages array
  // for each iteration, highlight the message by using message.i.content
  // as attribute for the highlight function
  const highlights = [...Array(hit?.messages?.length).keys()].map((i) => {
    return highlight({
      highlightProperty: "_highlightResult",
      attribute: `messages.${i}.content`,
      hit,
    });
  });

  // remove all entreis from highlights that are not highlighted
  const filteredHighlights = highlights.filter((entry) =>
    entry.some((item) => item.isHighlighted)
  );

  return (
    <Pressable
      onPress={() =>
        navigation.navigate("ChatNavigator", {
          screen: "ChatDetail",
          params: { chatId: hit?.id },
        })
      }
    >
      <VStack
        p={3}
        mb={3}
        space={3}
        borderRadius={"lg"}
        _light={{
          shadow: 1,
          bg: "gray.50",
        }}
        _dark={{
          bg: "gray.900",
        }}
      >
        <HStack
          space={5}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text maxWidth={"80%"}>
            {hit?.messages?.slice(0, 1)[0]?.content?.slice(0, 60) +
              (hit?.messages?.length > 60 ? "..." : "")}
          </Text>
          {filteredHighlights?.length > 0 && (
            <Box
              px={2}
              py={0.5}
              borderRadius={"full"}
              _dark={{
                bg: "primary.600",
              }}
              _light={{
                bg: "primary.100",
              }}
            >
              <Text
                fontSize={"xs"}
                _dark={{
                  color: "lightText",
                }}
                _light={{
                  color: "darkText",
                }}
              >
                {filteredHighlights?.length}
              </Text>
            </Box>
          )}
        </HStack>
        {filteredHighlights.slice(0, 2).map((highlightsArray, indexOne) => (
          <Text key={indexOne} fontSize={"xs"}>
            {highlightsArray
              .slice(0, 10)
              .map(({ value, isHighlighted }, indexTwo) => {
                return (
                  <Highlight
                    value={value}
                    isHighlighted={isHighlighted}
                    key={indexOne * indexTwo + indexOne + indexTwo}
                  />
                );
              })}
          </Text>
        ))}
      </VStack>
    </Pressable>
  );
};

ChatSearchResultListItem.propTypes = {
  attribute: PropTypes.string.isRequired,
  hit: PropTypes.object.isRequired,
  highlight: PropTypes.func.isRequired,
};

export default connectHighlight(ChatSearchResultListItem);
