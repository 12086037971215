import React from "react";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import * as Clipboard from "expo-clipboard";
import { Ionicons } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Toast, HStack, Icon, Input, Select, Stack, Text } from "native-base";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  dark,
  prism,
  funky,
  xonokai,
  nightOwl,
  a11yDark,
  atomDark,
  duotoneDark,
  duotoneEarth,
  coldarkCold,
  coldarkDark,
  materialDark,
  duotoneLight,
  materialLight,
  solarizedlight,
  materialOceanic,
} from "react-syntax-highlighter/dist/cjs/styles/prism";
// internal components
import { TranslationsContext } from "src/services/translations.context";

const CodeBlock = ({ className, children, inline }) => {
  const themes = {
    Dark: dark,
    Funky: funky,
    Prism: prism,
    Atom: atomDark,
    Xonokai: xonokai,
    "Night Owl": nightOwl,
    "A11y Dark": a11yDark,
    Material: materialOceanic,
    "Coldark Dark": coldarkDark,
    "Coldark Cold": coldarkCold,
    "Duotone Dark": duotoneDark,
    "Duotone Earth": duotoneEarth,
    "Duotone Light": duotoneLight,
    "Material Dark": materialDark,
    "Material Light": materialLight,
    "Solarized Light": solarizedlight,
  };
  const { i18n } = React.useContext(TranslationsContext);
  const [theme, setTheme] = React.useState(null);
  const [language, setLanguage] = React.useState(
    className ? className?.replace("language-", "") : "Code"
  );

  React.useEffect(() => {
    const loadTheme = async () => {
      const storedTheme =
        (await AsyncStorage.getItem("@codeblock_theme")) || "Material";
      if (storedTheme) setTheme(storedTheme);
    };

    loadTheme();
  }, []);

  React.useEffect(() => {
    if (theme) {
      AsyncStorage.setItem("@codeblock_theme", theme);
    }
  }, [theme]);

  const onPress = async () => {
    await Clipboard.setStringAsync(children[0]);
    Toast.show({
      placement: "top",
      bg: "primary.600",
      bgColor: "primary.300",
      title: i18n.t("general_copy_success_toast_title"),
    });
  };

  let extra;
  if (themes[theme]) {
    extra = {
      'code[class*="language-"]': {
        ...themes[theme]['code[class*="language-"]'],
        fontSize: "0.9em",
      },
      'pre[class*="language-"]': {
        ...themes[theme]['pre[class*="language-"]'],
        fontSize: "0.9em",
      },
    };
  }

  if (inline) {
    return (
      <Text
        py={1}
        px={2}
        borderRadius={"md"}
        _light={{
          bg: "gray.200",
        }}
        _dark={{
          bg: "gray.700",
        }}
      >
        {children?.length > 0 ? children[0] : ""}
      </Text>
    );
  } else {
    return (
      <>
        <Stack
          py={1}
          px={5}
          space={"xs"}
          borderTopRadius={"lg"}
          _dark={{ bg: "gray.800" }}
          _light={{ bg: "lightBackground" }}
          // size related
          direction={["column", "row"]}
          justifyContent={[undefined, "space-between"]}
          alignItems={[undefined, "space-between"]}
        >
          <HStack space={1} alignItems={"center"}>
            <Input
              size={"xs"}
              value={language}
              color={"#737373"}
              variant="unstyled"
              onChangeText={setLanguage}
              InputLeftElement={<Icon ml={3} as={Ionicons} name="code-slash" />}
            />
          </HStack>
          <HStack space={"sm"} alignItems={"center"}>
            <Select
              size={"xs"}
              selectedValue={theme}
              onValueChange={setTheme}
              placeholder="Choose theme"
              accessibilityLabel="Choose theme"
              dropdownIcon={
                <Icon
                  mr={1}
                  as={Ionicons}
                  color="primary.300"
                  name="caret-down-circle"
                />
              }
            >
              {Object.keys(themes).map((theme) => (
                <Select.Item key={theme} label={theme} value={theme} />
              ))}
            </Select>
            <Icon as={Ionicons} name="copy" onPress={onPress} />
          </HStack>
        </Stack>

        <SyntaxHighlighter
          wrapLines={true}
          wrapLongLines={true}
          showLineNumbers={!inline}
          style={{ ...themes[theme], ...extra }}
          language={language?.length > 1 ? language : "Not Detected"}
        >
          {children}
        </SyntaxHighlighter>
      </>
    );
  }
};

export const Markdown = ({ text, color = "white", textAlign = "left" }) => {
  const injectedStyles = color
    ? `
        .markdown-${textAlign} {
          color: ${color};
          font-size: 90%;
          font-weight: 500;
          text-align: ${textAlign};
          -webkit-user-select: text;
          -webkit-font-smoothing: antialiased;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }
      `
    : "";
  return (
    <>
      <style>{injectedStyles}</style>
      <ReactMarkdown
        children={text}
        escapeHtml={false}
        remarkPlugins={[remarkGfm]}
        components={{ code: CodeBlock }}
        className={`markdown-${textAlign}`}
        style={{
          color,
        }}
      />
    </>
  );
};
