import React from "react";
import { onSnapshot, doc } from "firebase/firestore";
import { AuthContext } from "src/services/auth.context";
import { FirebaseContext } from "src/services/firebase.context";

export const useUserDetails = () => {
  const { user } = React.useContext(AuthContext);
  const { firestore } = React.useContext(FirebaseContext);
  const [userDetail, setUserDetails] = React.useState(null);

  React.useEffect(() => {
    if (user?.uid) {
      console.debug(`👩🏼‍🎨 User Details ${user?.uid}`);
      const userDetailSnapshot = onSnapshot(
        doc(firestore, "users", user?.uid),
        (doc) => setUserDetails(doc.data())
      );
      return userDetailSnapshot;
    }
  }, [user?.uid]);

  return userDetail;
};
