import React from "react";
import { Dimensions } from "react-native";
import * as Clipboard from "expo-clipboard";
import { Ionicons } from "@expo/vector-icons";
import {
  Box,
  Icon,
  Text,
  Modal,
  Toast,
  Pressable,
  useBreakpointValue,
} from "native-base";
// internal components
import { Image } from "src/theme/media/image.component";
import { TranslationsContext } from "src/services/translations.context";

export const FullScreenImage = ({
  source,
  size = [200, 480],
  alt = "AI generated Image.",
  ...props
}) => {
  const { i18n } = React.useContext(TranslationsContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const drawerType = useBreakpointValue({ base: "slide", lg: "permanent" });
  const screenWidth =
    Dimensions.get("window").width - (drawerType === "slide" ? 0 : 250 + 25);
  return (
    <Box>
      <Modal size={"full"} isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Modal.Content maxWidth={screenWidth * 0.95}>
          <Modal.CloseButton />
          <Modal.Body alignItems={"center"}>
            <Image
              alt={alt}
              source={source}
              size={[
                screenWidth * 0.7,
                screenWidth * 0.65,
                screenWidth * 0.6,
                screenWidth * 0.55,
              ]}
            />
            <Pressable
              bg="white"
              onPress={async () => {
                await Clipboard.setStringAsync(alt);
                Toast.show({
                  placement: "top",
                  bg: "primary.600",
                  title: i18n.t("general_copy_success_toast_title"),
                });
              }}
            >
              <Text
                px={2}
                fontSize="lg"
                colorScheme={"white"}

              >
                {alt} <Icon as={Ionicons} name="copy" size="lg" color="white" />
              </Text>
            </Pressable>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <Pressable onPress={() => setIsOpen(true)}>
        <Image {...props} alt={alt} size={size} source={source} />
      </Pressable>
    </Box>
  );
};
