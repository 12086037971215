import React from "react";
import { Pressable } from "react-native";
import { Text, VStack, HStack } from "native-base";
import { useNavigation } from "@react-navigation/native";
// internal
import { TranslationsContext } from "src/services/translations.context";

export const ChatListItem = ({ chat }) => {
  const navigation = useNavigation();
  const { i18n } = React.useContext(TranslationsContext);
  const title =
    chat?.messages[0]?.role != "system"
      ? chat?.messages[0]?.content
      : chat?.messages[1]?.content;
  const description =
    chat?.messages[0]?.role != "system"
      ? chat?.messages[1]?.content
      : chat?.messages[2]?.content;

  return (
    <Pressable
      onPress={() =>
        navigation.navigate("ChatNavigator", {
          screen: "ChatDetail",
          params: { chatId: chat?.id },
        })
      }
    >
      <VStack
        p={3}
        space={"xs"}
        borderRadius={"lg"}
        _light={{
          shadow: 1,
          bg: "gray.50",
        }}
        _dark={{
          bg: "gray.900",
        }}
      >
        <Text
          textAlign={"left"}

          numberOfLines={1}
          fontSize={["md"]}
        >
          {title}
        </Text>

        {chat?.messages?.length >= 2 && (
          <Text textAlign={"left"} numberOfLines={2} fontSize="sm">
            {description}
          </Text>
        )}

        <Text fontSize={"2xs"} textAlign={"left"} colorScheme="muted">
          {new Date(chat.created_at.seconds * 992).toLocaleString(i18n.locale)}
        </Text>
        <HStack justifyContent="space-between" alignItems="flex-end">
          <Text color="primary.600">{chat?.messages?.length} messages</Text>
          <Text fontSize="xs">
            {chat?.messages.reduce((acc, message) => {
              return acc + message.tokens;
            }, 0)}{" "}
            tokens
          </Text>
        </HStack>
      </VStack>
    </Pressable>
  );
};
