import React from "react";
import { useForm } from "react-hook-form";
import { Platform } from "react-native";
import {
  VStack,
  KeyboardAvoidingView,
  HStack,
  Switch,
  Text,
} from "native-base";
// internal Context
import { AuthContext } from "src/services/auth.context";
import { ChatsContext } from "src/services/chats.context";
// internal components
import { useIsKeyboardVisible } from "src/utils/keyboard";
import { DynamicInput } from "src/theme/forms/dynamic-input.component";
import { TranslationsContext } from "src/services/translations.context";
import { AuthenticatedIconButton } from "src/theme/buttons/authenticated-icon-button.component";

const DEFAULT_MODEL = "gpt-4-1106-preview";

export const MessageCreate = ({ chatId, setChatId = undefined }) => {
  const [model, setModel] = React.useState(DEFAULT_MODEL);
  // init context
  const { user } = React.useContext(AuthContext);
  const { i18n } = React.useContext(TranslationsContext);
  const { selectedPersona } = React.useContext(ChatsContext);
  // init form
  const { control, handleSubmit, setValue } = useForm();
  // init hooks
  const { createChat, updateChat, stopChat, isStreaming } =
    React.useContext(ChatsContext);
  const isKeyboardVisible = useIsKeyboardVisible();
  // onSend
  const onSend = async (data) => {
    setValue("message", "");
    const payload = {
      model,
      content: data?.message,
      system_message: selectedPersona?.prompt,
    };
    if (!chatId) {
      const newChatId = await createChat();
      await setChatId(newChatId);
      setTimeout(() => updateChat(newChatId, payload), 250);
    } else {
      updateChat(chatId, payload);
    }
  };

  return (
    <KeyboardAvoidingView
      width={"100%"}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <HStack
        px={5}
        py={3}
        space={3}
        zIndex={10000}
        _dark={{ bg: "darkBackground" }}
        _light={{ bg: "lightBackground" }}
        safeAreaBottom={isKeyboardVisible ? undefined : true}
      >
        <DynamicInput
          // logic
          name={"message"}
          control={control}
          value={selectedPersona?.example}
          onSubmit={user && handleSubmit(onSend)}
          rules={{ required: true, maxLength: 7500, minLength: 0 }}
          // styling
          autoFocus={true}
          placeholder={i18n.t("message_create_placeholder")}
        />
        <VStack alignItems="center">
          {isStreaming && Platform.OS === "web" ? (
            <AuthenticatedIconButton
              // styling
              iconName="stop"
              // logic
              onPress={stopChat}
              colorScheme="danger"
              borderRadius={"full"}
              isLoading={!isStreaming}
            />
          ) : (
            <>
              <AuthenticatedIconButton
                // styling
                iconName="send"
                // logic
                colorScheme="primary"
                borderRadius={"full"}
                isLoading={isStreaming}
                onPress={handleSubmit(onSend)}
              />
              <VStack alignItems={"center"}>
                <Switch
                    size={"sm"}
                    isChecked={model == DEFAULT_MODEL}
                    onValueChange={(value) => {
                      setModel(value ? DEFAULT_MODEL : "gpt-3.5-turbo");
                    }}
                />
                  <Text  fontSize="2xs">{model == DEFAULT_MODEL ? "GPT-4" : "GPT-3.5"}</Text>
              </VStack>
            </>
          )}
        </VStack>
      </HStack>
    </KeyboardAvoidingView>
  );
};
