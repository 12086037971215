import React from "react";
import { Ionicons } from "@expo/vector-icons";
import { useController } from "react-hook-form";
import { TranslationsContext } from "src/services/translations.context";
import { Text, VStack, HStack, FlatList, Pressable, Icon } from "native-base";

const ImageCreateSettingsItem = ({
  name,
  label,
  data,
  control,
  defaultValue,
}) => {
  const { field } = useController({
    name: name,
    control: control,
    defaultValue: defaultValue,
  });

  return (
    <HStack alignItems="center" space={1}>
      <FlatList
        horizontal
        data={data}
        keyboardShouldPersistTaps="always"
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          alignItems: "center",
        }}
        ListHeaderComponent={
          <Icon
            _dark={{
              color: "lightText",
            }}
            _light={{
              color: "darkText",
            }}
            mx={2}
            size="md"
            as={Ionicons}
            name={label}
          />
        }
        renderItem={({ item }) => (
          <Pressable
            px={2}
            py={1}
            mx={2}
            borderRadius={"lg"}
            _dark={{
              bg: field.value == item ? "primary.400" : "gray.900",
            }}
            _light={{
              bg: field.value == item ? "primary.400" : "gray.200",
            }}
            onPress={() => {
              field.onChange(field.value == item ? null : item);
            }}
          >
            <Text
              fontSize={"md"}
              _dark={{
                color: field.value == item ? "lightText" : "lightText",
              }}
              _light={{
                color: field.value == item ? "lightText" : "darkText",
              }}
            >
              {item}
            </Text>
          </Pressable>
        )}
      />
    </HStack>
  );
};

export const ImageCreateSettings = ({ control, initialImage }) => {
  const { i18n } = React.useContext(TranslationsContext);
  return (
    <VStack space={5}>
      <ImageCreateSettingsItem
        control={control}
        name="settings.theme"
        defaultValue={initialImage?.settings?.theme}
        label={"brush"}
        data={[
          "3D Render",
          "Animated",
          "Black and White",
          "Realistic",
          "Stereoscopic",
          "Vector Art",
          "Hand-Drawn",
          "Pixel Art",
          "Sketch",
          "Watercolor",
          "Oil Painting",
          "Pastel",
          "Pen and Ink",
          "Charcoal",
          "Sculpture",
          "CGI",
          "Claymation",
          "Stop-Motion",
        ]}
      />
      <ImageCreateSettingsItem
        control={control}
        name="settings.perspective"
        defaultValue={initialImage?.settings?.perspective}
        label={"camera"}
        data={[
          "Aerial View",
          "Closeup",
          "Side View",
          "Front View",
          "Back View",
          "Top View",
          "Bottom View",
          "Bird's Eye View",
          "Worm's Eye View",
          "Overhead View",
          "Underwater View",
          "Microscopic View",
          "Telescopic View",
          "Panoramic View",
          "Oblique View",
          "Isometric View",
          "Perspective View",
          "Orthographic View",
          "Parallel View",
          "Cross Section",
        ]}
      />
    </VStack>
  );
};
