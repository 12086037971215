import React from "react";
import { TextArea, Text, VStack, HStack } from "native-base";
import { useController } from "react-hook-form";

export const DynamicInput = ({
  name,
  control,
  rules = {},
  value = null,
  maxHeight = 200,
  placeholder = "",
  autoFocus = false,
  onSubmit = undefined,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    defaultValue: value,
    name,
    rules,
  });

  React.useEffect(() => {
    if (value && field?.onChange) {
      field.onChange(value);
    }
  }, [value]);

  const valueLength = field?.value?.length ? field?.value?.length : 0;

  return (
    <VStack flex={1} space={"2xs"} width={"100%"}>
      <TextArea
        isInvalid={!!error}
        autoCorrect={false}
        value={field?.value}
        autoFocus={autoFocus}
        maxHeight={maxHeight}
        alignItems={"flex-end"}
        maxLength={rules?.maxLength}
        placeholder={placeholder}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            onSubmit && onSubmit();
          }
        }}
        onChangeText={(text) => {
          field.onChange(text);
        }}
      />
      <Text alignSelf={"flex-end"} pr={2} fontSize={"xs"} color={"gray.500"}>
        {field?.value?.length ? field?.value?.length : 0}/{rules?.maxLength}
      </Text>
    </VStack>
  );
};
