import React from "react";
import { Ionicons } from "@expo/vector-icons";
import { Button, HStack, Icon, Pressable, Text } from "native-base";
import { TranslationsContext } from "src/services/translations.context";

export const ChatCreate = ({ setChatId }) => {
  const { i18n } = React.useContext(TranslationsContext);
  return (
    <Button
      py={1}
      bg={"gray.500"}
      borderRadius={"md"}
      onPress={() => setChatId(null)}
      leftIcon={<Icon as={Ionicons} color="gray.50" name="add-circle" />}
    >
      {i18n.t("chat_create_button")}
    </Button>
  );
};
