import React from "react";
import { Platform } from "react-native";
import { Box, FlatList, HStack, VStack, useBreakpointValue } from "native-base";
// internal
import { useChats } from "../hooks/useChats";
import { ChatSearch } from "../components/chat-search.component";
import { ScreenFrame } from "src/theme/layout/screen-frame.component";
import { ChatListItem } from "../components/chat-list-item.component";

export const ChatListScreen = ({ navigation }) => {
  const chats = useChats(20);
  const [searchTerm, setSearchTerm] = React.useState("");
  const chatsData = chats.data?.pages
    .flatMap((page) => page)
    .map((doc) => doc.data());

  const numColumns = useBreakpointValue([1, 2, 3, 4]);

  return (
    <ScreenFrame
      px={5}
      space={5}
      withScrollView={false}
      onBack={() =>
        Platform.OS === "web"
          ? navigation.navigate("ChatHome")
          : navigation.goBack()
      }
    >
      <ChatSearch setSearchTerm={setSearchTerm} searchTerm={searchTerm} />

      {!searchTerm?.query && (
        <FlatList
          key={numColumns}
          numColumns={numColumns}
          data={chatsData}
          renderItem={({ item: chat }) => (
            <Box flex={1} p={3}>
              <ChatListItem chat={chat} navigation={navigation} />
            </Box>
          )}
          onEndReached={() =>
            !chats.isFetching && chats.hasNextPage && chats.fetchNextPage()
          }
        />
      )}
    </ScreenFrame>
  );
};
