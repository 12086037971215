import React from "react";
import PropTypes from "prop-types";
import { Ionicons } from "@expo/vector-icons";
import { HStack, Icon, Input, Pressable } from "native-base";
import { connectSearchBox } from "react-instantsearch-native";
import { TranslationsContext } from "src/services/translations.context";

const ChatSearchInput = ({ currentRefinement, refine }) => {
  const { i18n } = React.useContext(TranslationsContext);
  return (
    <HStack alignItems={"center"} justifyContent="space-between" space={3}>
      <Input
        flex={1}
        size={"lg"}
        _dark={{
          bg: "gray.900",
          _focus: {
            borderWidth: 1,
          },
          _hover: {
            borderWidth: 1,
          },
        }}
        value={currentRefinement}
        onChangeText={(value) => refine(value)}
        placeholder={i18n.t("general_search_placeholder")}
        InputRightElement={
          <Pressable
            pr={5}
            onPress={() => currentRefinement?.length > 0 && refine("")}
          >
            <Icon
              size="md"
              color={"gray.300"}
              as={Ionicons}
              name={
                currentRefinement?.length > 0
                  ? "close-circle"
                  : "search-outline"
              }
            />
          </Pressable>
        }
      />
    </HStack>
  );
};

ChatSearchInput.propTypes = {
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
};

export default connectSearchBox(ChatSearchInput);
