import React from "react";
import { IconButton } from "src/theme/buttons/icon-button.component";

export const NavigateToImageOnboardingButton = ({ navigation }) => (
  <IconButton
    onPress={() =>
      navigation.navigate("ImageNavigator", {
        screen: "ImageOnboarding",
      })
    }
    iconName="help-buoy"
  />
);
