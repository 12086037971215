import React from "react";
import { Platform } from "react-native";
import {
  Text,
  Input,
  VStack,
  Button,
  Pressable,
  KeyboardAvoidingView,
} from "native-base";
// internal
import { Logo } from "src/theme/media/logo.component";
import { AuthContext } from "src/services/auth.context";
import { UserLogin } from "../components/user-login.component";
import { ScreenFrame } from "src/theme/layout/screen-frame.component";
import { TranslationsContext } from "src/services/translations.context";

export const UserLoginScreen = ({ navigation }) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { i18n } = React.useContext(TranslationsContext);
  const { onRegister, isLoading, user, onLoginWithApple } =
    React.useContext(AuthContext);

  const onLoginPress = () => {
    onRegister(email, password);
  };

  const onRegisterPress = () => {
    if (email && password) {
      onRegister(email, password);
    } else {
      navigation.navigate("UserRegister");
    }
  };

  React.useEffect(() => {
    if (user) {
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        navigation.navigate("ImageNavigator");
      }
    }
  }, [user]);

  return (
    <KeyboardAvoidingView
      flex={1}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <ScreenFrame py={5} px={5}>
        <VStack
          flex={1}
          maxW={400}
          width="100%"
          space={"md"}
          borderRadius="lg"
          alignSelf={"center"}
          justifyContent={"center"}
        >
          {/* <Logo withNavigation={false} withText={false} /> */}
          {Platform.OS === "web" && <UserLogin />}
          <VStack space={5}>
            <Input
              size="lg"
              type="text"
              value={email}
              variant={"filled"}
              borderRadius={"sm"}
              autoCapitalize={"none"}
              onChangeText={setEmail}
              keyboardType="email-address"
              placeholder={i18n.t(
                "user_login_or_register_email_input_placeholder"
              )}
            />
            <Input
              size="lg"
              type="password"
              value={password}
              variant={"filled"}
              borderRadius={"sm"}
              disabled={isLoading}
              keyboardType="default"
              onChangeText={setPassword}
              placeholder={i18n.t(
                "user_login_or_register_password_input_placeholder"
              )}
            />
            <Pressable
              onPress={() => navigation.navigate("UserForgotPassword")}
            >
              <Text fontSize={"xs"} textAlign={"center"}>
                {i18n.t("user_forgot_password")}
              </Text>
            </Pressable>
          </VStack>

          <VStack space={"xs"} maxW={300} width={"100%"} alignSelf={"center"}>
            <Button
              variant={"solid"}
              borderRadius={"sm"}
              disabled={isLoading}
              isLoading={isLoading}
              onPress={onLoginPress}
            >
              {i18n.t("user_login")}
            </Button>
            <Button
              variant={"ghost"}
              borderRadius={"sm"}
              onPress={onRegisterPress}
            >
              {i18n.t("user_register")}
            </Button>
          </VStack>
        </VStack>
      </ScreenFrame>
    </KeyboardAvoidingView>
  );
};
