import React from "react";
import { extendTheme, NativeBaseProvider } from "native-base";
// internal
import { themeConfig } from "./theme.config";
import { TranslationsContext } from "src/services/translations.context";

const config = {
  dependencies: {
    "linear-gradient": require("expo-linear-gradient").LinearGradient,
  },
};

export const ThemeProvider = ({ children }) => {
  const { i18n } = React.useContext(TranslationsContext);

  // Initialise + Extend Native Base Theme
  const theme = extendTheme(themeConfig(i18n.locale));
  return (
    <NativeBaseProvider theme={theme} config={config}>
      {children}
    </NativeBaseProvider>
  );
};
