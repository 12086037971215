import React from "react";
import { Hidden, Text, VStack } from "native-base";
import { Logo } from "src/theme/media/logo.component";
import { TranslationsContext } from "src/services/translations.context";

export const ChatHeader = () => {
  const { i18n } = React.useContext(TranslationsContext);
  return (
    <Hidden from="lg">
      <VStack key="header" space={3} alignItems={"center"}>
        {/* <Logo withText={false} key="logo" /> */}
        <VStack alignItems={"center"} key="titleContainer">
          <Text
            key="title"
            fontSize={"xl"}

            letterSpacing={"lg"}
          >
            {i18n.t("chat_header_title")}
          </Text>
          <Text key="subtitle" fontSize={"2xs"} colorScheme="muted">
            {i18n.t("chat_header_subtitle")}
          </Text>
        </VStack>
      </VStack>
    </Hidden>
  );
};
