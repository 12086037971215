import React from "react";
import { useController } from "react-hook-form";
import {
  Text,
  Icon,
  VStack,
  HStack,
  FlatList,
  Pressable,
  FormControl,
  TextArea as NBTextArea,
  Spinner,
} from "native-base";
// internal
import { useYamli } from "../hooks/useYamli";
// internal
import { Ionicons } from "@expo/vector-icons";
import { TranslationsContext } from "src/services/translations.context";

export const ImageCreateYamliTextArea = ({
  name,
  control,
  rules = {},
  value = null,
  ...props
}) => {
  const yamli = useYamli();
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: rules,
    defaultValue: value,
  });
  return (
    <VStack width={"100%"}>
      <HStack py={2}>
        <FlatList
          inverted
          horizontal
          minHeight={35}
          data={yamli?.data?.r}
          showsHorizontalScrollIndicator={false}
          ListHeaderComponent={yamli.isLoading && <Spinner />}
          renderItem={({ item, index }) =>
            item && (
              <VStack justifyContent={"center"}>
                <Pressable
                  py={1}
                  px={2}
                  mx={1}
                  borderWidth={1}
                  borderRadius={"lg"}
                  _dark={{
                    borderColor: index == 0 ? "primary.600" : "lighText",
                  }}
                  _light={{
                    borderColor: index == 0 ? "primary.600" : "darkText",
                  }}
                  onPress={() =>
                    field.onChange(field.value.replace(yamli?.data?.w, item))
                  }
                >
                  <HStack space={1} alignItems={"center"}>
                    <Icon
                      size="sm"
                      as={Ionicons}
                      name={index == 0 ? "checkmark-circle" : "add-circle"}
                      _dark={{
                        color: index == 0 ? "primary.600" : "lightText",
                      }}
                      _light={{
                        color: index == 0 ? "primary.600" : "darkText",
                      }}
                    />
                    <Text
                      fontSize={"md"}

                      textAlign={"right"}
                      _dark={{
                        color: index == 0 ? "primary.600" : "lightText",
                      }}
                      _light={{
                        color: index == 0 ? "primary.600" : "darkText",
                      }}
                    >
                      {item}
                    </Text>
                  </HStack>
                </Pressable>
              </VStack>
            )
          }
        />
      </HStack>

      <FormControl isInvalid={!!error}>
        <VStack space={5}>
          <NBTextArea
            {...props}
            autoCorrect={false}
            textAlign={"right"}
            isInvalid={!!error}
            value={field?.value || ""}
            autoCompleteType={"off"}
            onChangeText={(text) => {
              if (
                yamli?.data &&
                !yamli.isLoading &&
                text.slice(-1) == " " &&
                yamli?.data?.r?.length > 0
              ) {
                field.onChange(text.replace(yamli?.data?.w, yamli?.data?.r[0]));
              } else {
                field.onChange(text);
                yamli.mutate(text.split(" ").slice(-1));
              }
            }}
            isRequired={!!rules?.required}
            InputLeftElement={
              field.value && (
                <Pressable pl={3} onPress={() => field.onChange("")}>
                  <Icon as={Ionicons} size="lg" name="close-circle" />
                </Pressable>
              )
            }
          />
        </VStack>
      </FormControl>
    </VStack>
  );
};
