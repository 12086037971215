import React from "react";
import { Pressable } from "native-base";
// internal hooks
import { useImageURL } from "src/features/images/hooks/useImageURL";
// internal components
import { Image } from "src/theme/media/image.component";

export const ImageListItem = React.memo(({ image, size, onPress }) => {
  const smallImageURL = useImageURL(image?.id, "128x128", false);
  const imageURL = useImageURL(image?.id);
  return (
    <Pressable onPress={onPress}>
      <Image
        size={size}
        alt={image?.prompt}
        source={{
          uri: smallImageURL?.isError ? imageURL?.data : smallImageURL?.data,
        }}
      />
    </Pressable>
  );
});
