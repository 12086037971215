import React from "react";
import { Ionicons } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Icon, HStack, useColorMode, Pressable } from "native-base";

export const ToggleThemeButton = () => {
  const { toggleColorMode, colorMode } = useColorMode();
  const [colorModeIsLoaded, setColorModelIsLoaded] = React.useState(false);

  React.useEffect(() => {
    AsyncStorage.getItem("@piasso_color_model").then((value) => {
      const newColorMode = value ? value : "light";
      if (newColorMode != colorMode) {
        toggleColorMode();
      }
      setColorModelIsLoaded(true);
      console.debug("🎭 Theme initialised with", newColorMode);
    });
  }, []);

  React.useEffect(() => {
    if (!!colorMode && colorModeIsLoaded) {
      AsyncStorage.setItem("@piasso_color_model", colorMode).then(() => {
        console.debug("🎭 Theme updated with", colorMode);
      });
    }
  }, [colorMode]);

  return (
    <Pressable width={"100%"} onPress={toggleColorMode}>
      <HStack justifyContent={"center"}>
        <Icon
          size={"lg"}
          as={Ionicons}
          color={colorMode == "dark" ? "gray.100" : "gray.900"}
          name={colorMode == "dark" ? "moon" : "sunny"}
        />
      </HStack>
    </Pressable>
  );
};
