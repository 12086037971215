import React from "react";
import PropTypes from "prop-types";
import { FlatList } from "native-base";
import { connectInfiniteHits } from "react-instantsearch-native";
import ImageSearchResultListItem from "./image-search-result-list-item.component";

const ImageSearchResultList = ({ hits, hasMore, refineNext }) => (
  <FlatList
    data={hits}
    numColumns={2}
    scrollEnabled={true}
    keyExtractor={(item) => item.objectID}
    onEndReached={() => hasMore && refineNext()}
    renderItem={({ item }) => (
      <ImageSearchResultListItem attribute="prompt" hit={item} />
    )}
  />
);

ImageSearchResultList.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasMore: PropTypes.bool.isRequired,
  refineNext: PropTypes.func.isRequired,
};

export default connectInfiniteHits(ImageSearchResultList);
