import React from "react";
import { useNavigation } from "@react-navigation/native";
import { VStack, Image, Text, Pressable } from "native-base";

export const Logo = ({
  size = "sm",
  fontSize = "xl",
  withText = true,
  text = "Piasso",
  withNavigation = true,
}) => {
  const navigation = useNavigation();

  const innerBody = (
    <VStack alignItems={"center"} space={1} pt={3}>
      <Image
        size={size}
        borderWidth={0}
        borderRadius={0}
        alt="Piasso Logo"
        resizeMode="contain"
        source={require("assets/logo.png")}
      />
      {withText && (
        <Text fontFamily={"caveat"} letterSpacing="2xl" fontSize={fontSize}>
          {text}
        </Text>
      )}
    </VStack>
  );
  if (withNavigation) {
    return (
      <Pressable
        onPress={() => {
          if (navigation.canGoBack()) navigation.goBack();
        }}
      >
        {innerBody}
      </Pressable>
    );
  } else {
    return innerBody;
  }
};
