import React from "react";
import axios from "axios";
import Constants from "expo-constants";
import { Platform } from "react-native";
import { getAuth, onIdTokenChanged } from "firebase/auth";

export const AxiosContext = React.createContext(null);

export const AxiosContextProvider = ({ children }) => {
  const baseURL = Constants.manifest.extra.API_BASE_URL;

  const headers = {
    platform: Platform.OS,
    accept: "application/json",
    version: Constants.manifest.version,
  };

  const client = React.useMemo(() => {
    const temp = axios.create({
      headers: headers,
      baseURL: baseURL,
    });
    return temp;
  }, []);

  React.useEffect(() => {
    onIdTokenChanged(getAuth(), (user) => {
      if (user?.stsTokenManager?.accessToken) {
        client.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${user?.stsTokenManager?.accessToken}`;
        console.debug(`🔑 Access Token added (${baseURL})`);
      } else {
        delete client.defaults.headers.common["Authorization"];
        console.debug(`🔑 Access Token removed (${baseURL})`);
      }
    });
  });

  return (
    <AxiosContext.Provider
      value={{
        client,
      }}
    >
      {children}
    </AxiosContext.Provider>
  );
};
