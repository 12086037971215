import React from "react";
import PropTypes from "prop-types";
import { Ionicons } from "@expo/vector-icons";
import { HStack, Icon, Input, Pressable } from "native-base";
import { connectSearchBox } from "react-instantsearch-native";
import { TranslationsContext } from "src/services/translations.context";

const ImageSearchInput = ({ currentRefinement, refine }) => {
  const { i18n } = React.useContext(TranslationsContext);
  return (
    <HStack
      px={5}
      space={3}
      alignItems={"center"}
      justifyContent="space-between"
    >
      <Input
        flex={1}
        variant="filled"
        value={currentRefinement}
        onChangeText={(value) => refine(value)}
        placeholder={i18n.t("general_search_placeholder")}
      />
      <Pressable
        p={1}
        onPress={() => currentRefinement?.length > 0 && refine("")}
      >
        <Icon
          size="md"
          as={Ionicons}
          name={currentRefinement?.length > 0 ? "close-circle" : "search"}
        />
      </Pressable>
    </HStack>
  );
};

ImageSearchInput.propTypes = {
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
};

export default connectSearchBox(ImageSearchInput);
