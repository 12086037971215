import React from "react";
import { Keyboard } from "react-native";
import { HStack, Box, useBreakpointValue } from "native-base";
import { useNavigation } from "@react-navigation/native";
import { IconButton } from "src/theme/buttons/icon-button.component";

export const NavigationHeader = ({
  onBack = undefined,
  headerRightComponent = undefined,
}) => {
  const navigation = useNavigation();
  const drawerType = useBreakpointValue({
    base: "slide",
    lg: "permanent",
  });

  return (
    <HStack px={3} alignItems="center" justifyContent={"space-between"}>
      <Box alignItems="flex-start">
        {onBack ? (
          <IconButton iconName="arrow-back" onPress={onBack} />
        ) : (
          <>
            {drawerType === "slide" && (
              <IconButton
                onPress={() => {
                  Keyboard.dismiss();
                  navigation.toggleDrawer();
                }}
                iconName="menu"
              />
            )}
          </>
        )}
      </Box>
      <Box alignItems="flex-end">
        {headerRightComponent && headerRightComponent}
      </Box>
    </HStack>
  );
};
