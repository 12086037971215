import React from "react";
import { Platform } from "react-native";
import * as Sharing from "expo-sharing";
import * as FileSystem from "expo-file-system";
import * as MediaLibrary from "expo-media-library";
import { HStack, Spinner, Toast } from "native-base";
import { IconButton } from "src/theme/buttons/icon-button.component";
import { TranslationsContext } from "src/services/translations.context";
import { ImageDetailDeleteButton } from "./image-detail-delete-button.component";

export const ImageDetailActions = ({ image, imageURL }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { i18n } = React.useContext(TranslationsContext);
  const [permissionResponse, requestPermission] = MediaLibrary.usePermissions();
  return (
    <HStack>
      {isLoading ? (
        <Spinner />
      ) : (
        <HStack>
          {Platform.OS !== "web" && (
            <>
              <IconButton
                isDisabled={isLoading}
                onPress={async () => {
                  setIsLoading(true);
                  if (!permissionResponse?.granted) {
                    console.debug("Image Permission needs to be requested...");
                    requestPermission();
                    setIsLoading(false);
                  } else {
                    const UTI = "public.item";
                    const fileUri = `${FileSystem.documentDirectory}${image.id}.png`;
                    const downloadedFile = await FileSystem.downloadAsync(
                      imageURL,
                      fileUri
                    );
                    const asset = await MediaLibrary.createAssetAsync(
                      downloadedFile.uri
                    );
                    Toast.show({
                      placement: "top",
                      bg: "primary.600",
                      title: i18n.t("image_detail_save_success_toast_title"),
                    });
                    setIsLoading(false);
                  }
                }}
                iconName="download"
              />

              <IconButton
                isDisabled={isLoading}
                onPress={async () => {
                  setIsLoading(true);
                  const UTI = "public.item";
                  const fileUri = `${FileSystem.documentDirectory}${image.id}.png`;
                  const downloadedFile = await FileSystem.downloadAsync(
                    imageURL,
                    fileUri
                  );
                  const shareResult = await Sharing.shareAsync(
                    downloadedFile.uri,
                    {
                      UTI,
                    }
                  );
                  setIsLoading(false);
                }}
                iconName="share-social"
              />
            </>
          )}

          <ImageDetailDeleteButton image={image} />
        </HStack>
      )}
    </HStack>
  );
};
