import React from "react";
import { Toast } from "native-base";
import { useMutation, useQueryClient } from "react-query";
// internal
import { AxiosContext } from "src/services/axios.context";
import { TranslationsContext } from "src/services/translations.context";

export const useDeleteImage = (imageID) => {
  const { client } = React.useContext(AxiosContext);
  const { i18n } = React.useContext(TranslationsContext);

  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return client.delete(`images/${imageID}/`);
    },
    {
      onSuccess: (resp) => {
        Toast.show({
          placement: "top",
          bg: "primary.600",
          title: i18n.t("image_delete_success_toast_title"),
        });
        queryClient.refetchQueries(["images"]);
      },
      onError: (error) => {
        console.error(error.response?.data);

        Toast.show({
          duration: 5000,
          bg: "danger.900",
          title: i18n.t("general_error_toast_title"),
          description:
            error.response?.status === 402
              ? i18n.t(error?.response?.data?.detail)
              : i18n.t("general_error_toast_description"),
        });
      },
      enabled: !!imageID,
    }
  );
};
