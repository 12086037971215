import React from "react";
import { useQuery } from "react-query";
import { ref, getDownloadURL } from "firebase/storage";
import { AuthContext } from "src/services/auth.context";
import { FirebaseContext } from "src/services/firebase.context";

export const useImageURL = (imageID, size = "", retry = true) => {
  const { user } = React.useContext(AuthContext);
  const { storage } = React.useContext(FirebaseContext);
  return useQuery(
    ["images", imageID, size, "url"],
    () => {
      // console.debug(`🌃 Image URL ${imageID}`);
      if (!imageID) {
        return Promise.resolve(null);
      }
      return getDownloadURL(
        ref(
          storage,
          `gs://piasso.appspot.com/users/${user.uid}/images/${imageID}${
            size ? "_" + size : ""
          }.png`
        )
      ).then((url) => url);
    },
    {
      retry: retry,
      enabled: !!imageID,
      staleTime: Infinity,
      onError: (error) => console.error(error),
    }
  );
};
