import React from "react";
import { Toast } from "native-base";
import { useQueryClient } from "react-query";
import {
  signOut,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onIdTokenChanged,
  sendEmailVerification,
} from "firebase/auth";
import { TranslationsContext } from "./translations.context";
import { FirebaseContext } from "src/services/firebase.context";

export const AuthContext = React.createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const { i18n } = React.useContext(TranslationsContext);
  // intialise auth with app
  const { auth } = React.useContext(FirebaseContext);

  const queryClient = useQueryClient();

  React.useEffect(() => {
    console.debug("👂 Auth Listener");
    onIdTokenChanged(auth, (usr) => {
      console.debug("🔊 Auth Listenening...");
      if (usr) {
        setUser(usr);
      }
      queryClient.removeQueries();
      setIsLoading(false);
    });
  }, []);

  const onLogin = (email, password) => {
    console.debug(`🔑 Login with email: ${email}`);
    setIsLoading(true);
    auth.useDeviceLanguage();
    signInWithEmailAndPassword(auth, email, password)
      .then((u) => {
        setUser(u?.user);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        Toast.show({
          bg: "danger.900",
          title: i18n.t("general_error_toast_title"),
          description: i18n.t("user_login_or_register_error_toast_description"),
        });
        setIsLoading(false);
      });
  };

  const onRegister = (email, password) => {
    console.debug(`🔑 Register with email: ${email}`);
    setIsLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then((u) => {
        setUser(u?.user);
        setIsLoading(false);
      })
      .catch((e) => {
        if (e.code === "auth/email-already-in-use") {
          onLogin(email, password);
        } else {
          Toast.show({
            bg: "danger.900",
            title: i18n.t("general_error_toast_title"),
            description: i18n.t(
              "user_login_or_register_error_toast_description"
            ),
          });
          console.error(e);
          setIsLoading(false);
        }
      });
  };

  const onLogout = () => {
    setIsLoading(true);
    console.debug(`🔑 Logout with email: ${user?.email}`);
    signOut(auth)
      .then(() => {
        setUser(null);
        setIsLoading(false);
        // navigation.navigate("UserNavigator", { screen: "UserLogin" });
      })
      .catch(() => setIsLoading(false));
  };

  const onSendPasswordResetEmail = (email) => {
    console.debug(`🔑 Send password reset email to: ${email}`);
    setIsLoading(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        Toast.show({
          bg: "primary.600",
          title: i18n.t("user_forgot_password_success_toast_title"),
          description: i18n.t("user_forgot_password_success_toast_description"),
        });
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        Toast.show({
          bg: "danger.900",
          title: i18n.t("general_error_toast_title"),
          description: i18n.t("general_error_toast_description"),
        });
        setIsLoading(false);
      });
  };

  const onSendValidationEmail = () => {
    console.debug(`🔑 Send validation email to: ${user?.email}`);
    setIsLoading(true);
    sendEmailVerification(user)
      .then(() => {
        Toast.show({
          bg: "primary.600",
          title: i18n.t("user_send_validation_email_success_toast_title"),
          description: i18n.t(
            "user_send_validation_email_success_toast_description"
          ),
        });
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        Toast.show({
          bg: "danger.900",
          title: i18n.t("general_error_toast_title"),
          description: i18n.t("general_error_toast_description"),
        });
        setIsLoading(false);
      });
  };

  const onDeleteAccount = () => {
    console.debug(`🔑 Delete account: ${user?.email}`);
    setIsLoading(true);
    user
      .delete()
      .then(() => {
        Toast.show({
          bg: "primary.600",
          title: i18n.t("user_delete_account_success_toast_title"),
          description: i18n.t("user_delete_account_success_toast_description"),
        });
        setUser(null);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        Toast.show({
          bg: "danger.900",
          title: i18n.t("general_error_toast_title"),
          description: i18n.t("general_error_toast_description"),
        });
        setIsLoading(false);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        onLogin,
        onLogout,
        isLoading,
        onRegister,
        onDeleteAccount,
        onSendValidationEmail,
        onSendPasswordResetEmail,
        isAuthenticated: !!user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
