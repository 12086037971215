import React from "react";
import { FlatList, Heading, Text, VStack } from "native-base";
import { TranslationsContext } from "src/services/translations.context";
import { FullScreenImage } from "src/theme/media/full-screen-image.component";

export const ImageListExamples = ({
  itemSize,
  initialNumToRender,
  ...props
}) => {
  const options = require("assets/options.json");
  const { i18n } = React.useContext(TranslationsContext);
  const [size, setSize] = React.useState(initialNumToRender);
  const [allImages, setAllImages] = React.useState([]);
  const images = React.useMemo(() => {
    return allImages.slice(0, size);
  }, [allImages, size]);

  React.useEffect(() => {
    let tempImages = [];
    for (const level in options) {
      const levelOneDict = options[level];
      for (const levelTwo in levelOneDict["options"]) {
        const levelTwoDict = levelOneDict["options"][levelTwo];
        for (const levelThree in levelTwoDict["options"]) {
          const levelThreeDict = levelTwoDict["options"][levelThree];
          const array = Array.from({ length: 10 }, (_, i) => {
            return {
              url: levelThreeDict["images_url_prefix"] + i + ".jpg",
              prompt:
                levelOneDict[`label_${i18n.locale}`] +
                " " +
                levelTwoDict[`label_${i18n.locale}`] +
                " " +
                levelThreeDict[`label_${i18n.locale}`],
            };
          });

          tempImages = [...tempImages, ...array];
        }
      }
    }
    tempImages.sort(() => Math.random() - 0.5);
    setAllImages(tempImages);
  }, []);

  return (
    <FlatList
      {...props}
      data={images}
      initialNumToRender={initialNumToRender}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      onEndReached={() => setSize(size + 10)}
      renderItem={({ item }) => (
        <FullScreenImage
          alt={item?.prompt}
          borderWidth={2}
          size={itemSize}
          source={{ uri: item?.url }}
          _dark={{ borderColor: "darkBackground" }}
          _light={{ borderColor: "lightBackground" }}
        />
      )}
    />
  );
};
