import React from "react";
import PropTypes from "prop-types";
import { Dimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { connectHighlight } from "react-instantsearch-native";
import { Pressable, Text, useBreakpointValue, VStack } from "native-base";
// internal
import { ImageListItem } from "./image-list-item.component";

const Highlight = ({ value, isHighlighted }) => {
  const maxLength = 25;
  value =
    value.length > maxLength
      ? value.slice(0, 25) + "..." + value.slice(-25)
      : value;
  return (
    <Text
      _dark={{
        color: isHighlighted ? "lightText" : undefined,
        bg: isHighlighted ? "primary.600" : undefined,
      }}
      _light={{
        color: isHighlighted ? "darkText" : undefined,
        bg: isHighlighted ? "primary.100" : undefined,
      }}
    >
      {value}
    </Text>
  );
};

const ImageSearchResultListItem = ({ attribute, hit, highlight }) => {
  const navigation = useNavigation();
  const highlights = highlight({
    highlightProperty: "_highlightResult",
    attribute,
    hit,
  });
  const drawerType = useBreakpointValue({ base: "slide", lg: "permanent" });

  const screenWidth =
    Dimensions.get("window").width - (drawerType === "slide" ? 0 : 250 + 25);

  const onPress = () =>
    navigation.navigate("ImageNavigator", {
      screen: "ImageDetail",
      params: { imageID: hit?.id },
    });

  return (
    <Pressable p={1} flex={1} onPress={onPress}>
      <VStack
        p={3}
        mb={3}
        space={3}
        alignItems="center"
        borderRadius={"lg"}
        _light={{
          bg: "dark.900",
        }}
        _dark={{
          bg: "dark.50",
        }}
      >
        <ImageListItem onPress={onPress} image={hit} size={screenWidth / 3} />

        <Text>
          {highlights.map((highlight, index) => (
            <Highlight
              key={index}
              value={highlight.value}
              isHighlighted={highlight.isHighlighted}
            />
          ))}
        </Text>
      </VStack>
    </Pressable>
  );
};

ImageSearchResultListItem.propTypes = {
  attribute: PropTypes.string.isRequired,
  hit: PropTypes.object.isRequired,
  highlight: PropTypes.func.isRequired,
};

export default connectHighlight(ImageSearchResultListItem);
