import React from "react";
import { VStack, Button, Toast, Text } from "native-base";
import { useNavigation } from "@react-navigation/native";
// internal services
import { AuthContext } from "src/services/auth.context";
import { TranslationsContext } from "src/services/translations.context";
// internal hooks
import { useImageURL } from "../hooks/useImageURL";
import { useUpdateImage } from "../hooks/useUpdateImage";
import { TimedProgressBar } from "src/theme/forms/timed-progress-bar.component";

export const ImageVariateButton = React.memo(({ initialImage }) => {
  const navigation = useNavigation();
  const imageURL = useImageURL(initialImage?.id);
  const updateImage = useUpdateImage(initialImage?.id);
  const { user } = React.useContext(AuthContext);
  const { i18n } = React.useContext(TranslationsContext);

  const onUpdateImage = (data) => {
    // navigate to login automatically if user is not signed in
    if (!user) {
      Toast.show({
        bg: "primary.600",
        title: i18n.t("user_login_or_register_toast_title"),
        description: i18n.t("user_login_or_register_toast_description"),
      });
      navigation.navigate("UserNavigator", { screen: "UserLogin" });
    } else {
      console.debug("🎨 Image variate ", initialImage?.id);
      updateImage.mutate({ action: "variate", url: imageURL.data });
    }
  };

  return (
    <VStack space={3} px={3} flex={1}>
      <Button
        py={3}
        px={5}
        variant={"solid"}
        colorScheme="tertiary"
        onPress={onUpdateImage}
        _text={{

          fontSize: ["sm", "md", "lg"],
        }}
        disabled={updateImage.isLoading || imageURL.isLoading}
        isLoading={updateImage.isLoading || imageURL.isLoading}
      >
        {i18n.t("image_variate_button")}
      </Button>
      <Text textAlign={"center"} fontSize={"xs"}>
        {i18n.t("image_variate_helper_text")}
      </Text>

      {updateImage.isLoading && (
        <VStack flex={1}>
          <TimedProgressBar seconds={20} />
        </VStack>
      )}
    </VStack>
  );
});
