import React from "react";
import { Keyboard } from "react-native";

export const KeyboardContext = React.createContext(null);

export const KeyboardContextProvider = ({ children }) => {
  const [keyboardOpen, setKeyboardOpen] = React.useState(false);

  React.useEffect(() => {
    const keyboardWillShowListener = Keyboard.addListener(
      "keyboardWillShow",
      () => setKeyboardOpen(true)
    );
    const keyboardWillHideListener = Keyboard.addListener(
      "keyboardWillHide",
      () => setKeyboardOpen(false)
    );

    return () => {
      keyboardWillShowListener.remove();
      keyboardWillHideListener.remove();
    };
  }, []);

  return (
    <KeyboardContext.Provider
      value={{
        keyboardOpen,
      }}
    >
      {children}
    </KeyboardContext.Provider>
  );
};
