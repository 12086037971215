import React from "react";
import { StatusBar } from "expo-status-bar";
import { VStack, ScrollView, useColorMode } from "native-base";
import { NavigationHeader } from "src/navigation/components/navigation-header.component";

export const ScreenFrame = ({
  children,
  px = 0,
  py = 0,
  space = 0,
  onBack = undefined,
  safeAreaTop = true,
  logoIsShown = false,
  headerIsShown = true,
  withScrollView = true,
  safeAreaBottom = true,
  navigatorName = undefined,
  refreshControl = undefined,
  footerComponent = undefined,

  justifyContent = "space-between",
  headerRightComponent = undefined,
}) => {
  const { colorMode } = useColorMode();
  return (
    <VStack flex={1}>
      <StatusBar style={colorMode === "light" ? "dark" : "light"} />

      <VStack
        flex={1}
        _dark={{
          bg: "darkBackground",
        }}
        _light={{
          bg: "lightBackground",
        }}
        safeAreaTop={safeAreaTop ? safeAreaTop : undefined}
        safeAreaBottom={safeAreaBottom ? safeAreaBottom : undefined}
      >
        {headerIsShown && (
          <NavigationHeader
            onBack={onBack}
            logoIsShown={logoIsShown}
            navigatorName={navigatorName}
            headerRightComponent={headerRightComponent}
          />
        )}

        {withScrollView ? (
          <ScrollView
            px={px}
            refreshControl={refreshControl}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps={"handled"}
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: "center",
            }}
          >
            <VStack flex={1} py={py} space={space}>
              {children}
            </VStack>
          </ScrollView>
        ) : (
          <VStack
            space={space}
            flex={1}
            py={py}
            px={px}
            justifyContent={justifyContent}
          >
            {children}
          </VStack>
        )}

        {footerComponent && footerComponent}
      </VStack>
    </VStack>
  );
};
