import { Platform } from "react-native";
import * as Linking from "expo-linking";
import { NavigationContainer } from "@react-navigation/native";
// internal screens
import { appRoutesConfig } from "./route.config";
import { DrawerNavigation } from "./drawer.navigation";

export const Navigation = () => {
  return (
    <NavigationContainer
      documentTitle={{
        enabled: false,
        formatter: "piasso",
      }}
      linking={
        Platform.OS === "web"
          ? {
              enabled: true,
              config: appRoutesConfig,
              prefixes: [
                Linking.createURL("/"),
                "https://piasso.xyz/",
                "https://app.piasso.xyz/",
              ],
            }
          : undefined
      }
    >
      <DrawerNavigation key={"DrawerNavigation"} />
    </NavigationContainer>
  );
};
