import React from "react";
import * as Clipboard from "expo-clipboard";
import { Ionicons } from "@expo/vector-icons";
import {
  Box,
  Text,
  Icon,
  Stack,
  VStack,
  HStack,
  Toast,
  Pressable,
} from "native-base";
import { TranslationsContext } from "src/services/translations.context";

export const ChatEmpty = () => {
  const information = require("assets/chat_examples.json");
  const { i18n } = React.useContext(TranslationsContext);
  return (
    <Stack
      flex={1}
      p={5}
      space={5}
      key="empty"
      direction={["column", "column", "row"]}
    >
      {information.map((item) => (
        <VStack
          flex={[undefined, undefined, 1]}
          space={5}
          alignItems="center"
          justifyContent="center"
          key={item.title + "Container"}
        >
          <Icon
            _light={{
              color: item.icon_color + ".600",
            }}
            _dark={{
              color: item.icon_color + ".300",
            }}
            as={Ionicons}
            name={item.icon}
            size={10}
          />
          <Text
            _light={{
              color: item.icon_color + ".600",
            }}
            _dark={{
              color: item.icon_color + ".300",
            }}
            key={item.title}
            fontSize={"lg"}

            textAlign={"center"}
          >
            {item.title}
          </Text>
          <VStack justifyContent={"center"} space={5}>
            {item.values.map((value) => (
              <Box
                borderRadius={"lg"}
                key={value + "Box"}
                _dark={{ bg: "gray.900" }}
                _light={{ bg: "gray.100" }}
              >
                <HStack justifyContent={"space-between"}>
                  {item.title === "Examples" ? (
                    <Pressable
                      width={"100%"}
                      onPress={async () => {
                        await Clipboard.setStringAsync(value);
                        Toast.show({
                          placement: "top",
                          bg: "primary.600",
                          title: i18n.t("general_copy_success_toast_title"),
                        });
                      }}
                    >
                      <Text
                        p={5}
                        key={value}
                        textAlign={"left"}
                        colorScheme={"muted"}
                      >
                        {value}
                        {"  "}
                        {item.title === "Examples" && (
                          <Icon as={Ionicons} name="copy" />
                        )}
                      </Text>
                    </Pressable>
                  ) : (
                    <Text
                      p={5}
                      key={value}
                      textAlign={"left"}
                      colorScheme={"muted"}
                    >
                      {value}
                    </Text>
                  )}
                </HStack>
              </Box>
            ))}
          </VStack>
        </VStack>
      ))}
    </Stack>
  );
};
