import React from "react";
import { I18n } from "i18n-js";
import { Toast } from "native-base";
import { getLocales } from "expo-localization";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const TranslationsContext = React.createContext();

export const TranslationsContextProvider = ({ children }) => {
  const [isYamliEnabled, setIsYamliEnabled] = React.useState(null);

  const [i18n, setI18n] = React.useState(null);

  const localizeNumber = (number) => {
    const locale = i18n.locale == "ar" ? "ar-EG" : i18n.locale;
    return parseFloat(number).toLocaleString(locale);
  };

  const updateLocale = (languageCode) => {
    console.debug(`Update Langauge Code: ${languageCode}`);
    i18n.locale = languageCode;
    AsyncStorage.setItem("@piasso_locale", languageCode).then(() => {
      console.debug(`Updated Langauge Code: ${i18n.locale}`);
      Toast.show({
        bg: "primary.600",
        title: i18n.t("device_language_updated_toast_title"),
        description: i18n.t("device_language_updated_toast_description"),
      });
    });
  };

  React.useEffect(() => {
    AsyncStorage.getItem("@piasso_locale").then((value) => {
      const tempI18n = new I18n({
        en: require("translations/en.json"),
        de: require("translations/de.json"),
        ar: require("translations/ar.json"),
      });
      const language_code = value ? value : getLocales()[0].languageCode;
      console.debug("🌎 Localization initialised with", language_code);
      tempI18n.locale = language_code;
      tempI18n.enableFallback = true;
      setI18n(tempI18n);
    });
    AsyncStorage.getItem("@piasso_is_yamli_enabled").then((value) => {
      const tempIsYamliEnabled = value == "true" ? true : false;
      console.debug("🌎 Yamli initialised with", tempIsYamliEnabled);
      setIsYamliEnabled(tempIsYamliEnabled);
    });
  }, []);

  React.useEffect(() => {
    if (isYamliEnabled != null) {
      AsyncStorage.setItem(
        "@piasso_is_yamli_enabled",
        isYamliEnabled.toString()
      ).then(() => {
        console.debug("🌎 Yamli updated with", isYamliEnabled);
      });
    }
  }, [isYamliEnabled]);

  return (
    <TranslationsContext.Provider
      value={{
        i18n,
        updateLocale,
        localizeNumber,
        isYamliEnabled,
        setIsYamliEnabled,
      }}
    >
      {!!i18n && isYamliEnabled != null && children}
    </TranslationsContext.Provider>
  );
};
