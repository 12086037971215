import React from "react";
import { Platform } from "react-native";
import { VStack, Button, Text, Input, KeyboardAvoidingView } from "native-base";
// internal
import { Logo } from "src/theme/media/logo.component";
import { AuthContext } from "src/services/auth.context";
import { ScreenFrame } from "src/theme/layout/screen-frame.component";
import { TranslationsContext } from "src/services/translations.context";

export const UserForgotPasswordScreen = ({ navigation }) => {
  const [email, setEmail] = React.useState("");
  const { i18n } = React.useContext(TranslationsContext);
  const { onSendPasswordResetEmail, isLoading } = React.useContext(AuthContext);

  return (
    <KeyboardAvoidingView
      flex={1}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <ScreenFrame
        py={5}
        px={5}
        onBack={() => navigation.navigate("UserLogin")}
      >
        <VStack
          flex={1}
          maxW={500}
          width="100%"
          space={"md"}
          borderRadius="lg"
          alignSelf={"center"}
          justifyContent={"center"}
        >
          <Text fontSize={"md"}>
            {i18n.t("user_forgot_password_helper_text")}
          </Text>
          <Input
            size={"lg"}
            type="email"
            value={email}
            variant={"filled"}
            borderRadius={"sm"}
            onChangeText={setEmail}
            keyboardType="email-address"
            placeholder={i18n.t(
              "user_login_or_register_email_input_placeholder"
            )}
          />
          <VStack space={5}>
            <Button
              variant={"solid"}
              borderRadius={"sm"}
              isLoading={isLoading}
              isDisabled={email.length === 0}
              onPress={() => onSendPasswordResetEmail(email)}
            >
              {i18n.t("general_continue_button")}
            </Button>
            <Button
              variant={"ghost"}
              borderRadius={"sm"}
              onPress={() => {
                navigation.navigate("UserLogin");
              }}
            >
              {i18n.t("general_go_back_button")}
            </Button>
          </VStack>
        </VStack>
      </ScreenFrame>
    </KeyboardAvoidingView>
  );
};
