import React from "react";
import { Ionicons } from "@expo/vector-icons";
import { Platform, RefreshControl } from "react-native";
import { FlatList, VStack, Icon, IconButton, Box } from "native-base";
// internal components
import { Message } from "src/features/messages/components/message.component";
import { ChatEmpty } from "src/features/chats/components/chat-empty.component";
import { ChatHeader } from "src/features/chats/components/chat-header.component";
import { ChatsContext } from "src/services/chats.context";

export const MessageList = ({ chat, chatId }) => {
  const ref = React.useRef(null);
  const { isStreaming } = React.useContext(ChatsContext);
  const [showScroll, setShowScroll] = React.useState(false);

  const onScroll = (event) => {
    const offsetY = event.nativeEvent.contentOffset.y;
    const contentHeight = event.nativeEvent.contentSize.height;
    const listHeight = event.nativeEvent.layoutMeasurement.height;
    const distanceFromBottom = contentHeight - listHeight - offsetY;
    setShowScroll(distanceFromBottom > 150);
  };

  const onPress = () => {
    if (ref.current) {
      ref.current.scrollToEnd({ animated: true });
    }
  };

  React.useEffect(() => {
    if (chat?.data?.messages?.length > 0) {
      setTimeout(onPress, 100);
    }
  }, [chat?.data?.messages?.length]);

  if (!chat?.isLoading || chat?.data?.messages) {
    return (
      <>
        <FlatList
          ref={ref}
          onScroll={onScroll}
          data={chat?.data?.messages || []}
          ListEmptyComponent={<ChatEmpty />}
          ListHeaderComponent={<ChatHeader />}
          showsVerticalScrollIndicator={false}
          _contentContainerStyle={{ flexGrow: 1 }}
          ListFooterComponent={<Box height={[50, 75, 100]} />}
          renderItem={({ item, index }) => (
            <Message message={item} index={index} />
          )}
          refreshControl={
            Platform.OS !== "web" && chatId && <RefreshControl query={chat} />
          }
        />
        {showScroll && chat?.data?.messages && (
          <IconButton
            onPress={onPress}
            // styling
            size={"lg"}
            right={10}
            bottom={10}
            variant={"solid"}
            borderRadius="full"
            position={"absolute"}
            icon={<Icon name="arrow-down" as={Ionicons} />}
          />
        )}
      </>
    );
  }
};
