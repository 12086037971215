import React from "react";
import { VStack, HStack } from "native-base";
// internal services
import { TranslationsContext } from "src/services/translations.context";
// internal hooks
import { useCreateImage } from "../hooks/useCreateImage";
import { TimedProgressBar } from "src/theme/forms/timed-progress-bar.component";
import { AuthenticatedButton } from "src/theme/buttons/authenticated-button.component";

export const ImageCreateButton = React.memo(({ handleSubmit }) => {
  const createImage = useCreateImage();
  const { i18n } = React.useContext(TranslationsContext);

  const onCreateImage = (data) => {
    console.debug("🎨 Image with prompt ", data?.prompt);
    createImage.mutate(data);
  };

  return (
    <VStack space={3} px={3}>
      <HStack space={3} justifyContent={"flex-end"}>
        <AuthenticatedButton
          p={3}
          px={10}
          variant={"solid"}
          _text={{
            fontSize: ["md", "lg", "xl"],
          }}
          // logic
          isLoading={createImage.isLoading}
          onPress={handleSubmit(onCreateImage)}
        >
          {i18n.t("image_create_button")}
        </AuthenticatedButton>
      </HStack>

      {createImage.isLoading && (
        <VStack flex={1}>
          <TimedProgressBar seconds={10} />
        </VStack>
      )}
    </VStack>
  );
});
