import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
// internal
import { AuthContext } from "src/services/auth.context";
import { UserLoginScreen } from "src/features/users/screens/user-login.screen";
import { UserDetailScreen } from "src/features/users/screens/user-detail.screen";
import { UserRegisterScreen } from "src/features/users/screens/user-register.screen";
import { UserForgotPasswordScreen } from "src/features/users/screens/user-forgot-password.screen";

const Stack = createStackNavigator();

export const UserNavigator = () => {
  const { user, isLoading } = React.useContext(AuthContext);
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        cardStyle: {
          flex: 1,
        },
      }}
    >
      {user?.uid && (
        <Stack.Group>
          <Stack.Screen component={UserDetailScreen} name="UserDetail" />
        </Stack.Group>
      )}
      {(!user?.uid || isLoading) && (
        <Stack.Group>
          <Stack.Screen component={UserLoginScreen} name="UserLogin" />
          <Stack.Screen component={UserRegisterScreen} name="UserRegister" />
          <Stack.Screen
            component={UserForgotPasswordScreen}
            name="UserForgotPassword"
          />
        </Stack.Group>
      )}
    </Stack.Navigator>
  );
};
