import React from "react";
import { useNavigation } from "@react-navigation/native";
import { VStack, Button, Toast, HStack, Text } from "native-base";
// internal services
import { AuthContext } from "src/services/auth.context";
import { TranslationsContext } from "src/services/translations.context";
// internal hooks
import { TimedProgressBar } from "src/theme/forms/timed-progress-bar.component";
import { useUpdateImage } from "../hooks/useUpdateImage";
import { useImageURL } from "../hooks/useImageURL";

export const ImageEditButton = React.memo(({ handleSubmit, initialImage }) => {
  const navigation = useNavigation();
  const imageURL = useImageURL(initialImage?.id);
  const updateImage = useUpdateImage(initialImage?.id);
  const { user } = React.useContext(AuthContext);
  const { i18n } = React.useContext(TranslationsContext);

  const onEditImage = (data) => {
    // navigate to login automatically if user is not signed in
    if (!user) {
      Toast.show({
        bg: "primary.600",
        title: i18n.t("user_login_or_register_toast_title"),
        description: i18n.t("user_login_or_register_toast_description"),
      });
      navigation.navigate("UserNavigator", { screen: "UserLogin" });
    } else {
      console.debug(
        `🎨 Edit Image ${initialImage?.id} with prompt `,
        data?.prompt
      );
      updateImage.mutate({ action: "edit", url: imageURL?.data, ...data });
    }
  };

  return (
    <VStack space={3} px={3} flex={1}>
      <Button
        py={3}
        px={5}
        variant={"solid"}
        colorScheme="primary"
        onPress={handleSubmit(onEditImage)}
        _text={{

          fontSize: ["sm", "md", "lg"],
        }}
        isLoading={updateImage.isLoading}
        disabled={updateImage.isLoading || imageURL.isLoading}
      >
        {i18n.t("image_edit_button")}
      </Button>
      <Text textAlign={"center"} fontSize={"xs"}>
        {i18n.t("image_edit_helper_text")}
      </Text>
      {updateImage.isLoading && (
        <VStack flex={1}>
          <TimedProgressBar seconds={20} />
        </VStack>
      )}
    </VStack>
  );
});
