import React from "react";
import { Toast } from "native-base";
import { IconButton } from "./icon-button.component";
import { AuthContext } from "src/services/auth.context";
import { useNavigation } from "@react-navigation/native";
import { TranslationsContext } from "src/services/translations.context";

export const AuthenticatedIconButton = ({ onPress, ...props }) => {
  const { user } = React.useContext(AuthContext);
  const { i18n } = React.useContext(TranslationsContext);
  const navigation = useNavigation();
  return (
    <IconButton
      {...props}
      onPress={() => {
        if (!user) {
          Toast.show({
            bg: "primary.600",
            title: i18n.t("user_login_or_register_toast_title"),
            description: i18n.t("user_login_or_register_toast_description"),
          });
          navigation.navigate("UserNavigator", { screen: "UserLogin" });
        } else {
          onPress();
        }
      }}
    />
  );
};
