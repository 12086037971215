import React from "react";
import { Dimensions } from "react-native";
import { HStack, Spinner, useBreakpointValue, VStack } from "native-base";
// internal
import { AuthContext } from "src/services/auth.context";
import { ScreenFrame } from "src/theme/layout/screen-frame.component.js";
import { ImageList } from "src/features/images/components/image-list.component";
import { ImageCreate } from "src/features/images/components/image-create.component";
import { ImageListItem } from "src/features/images/components/image-list-item.component";
import { CheckoutButton } from "src/features/purchases/components/checkout-button.component";
import { ImageListExamples } from "src/features/images/components/image-list-examples.component";
import { NavigateToImageListButton } from "src/features/images/components/navigator-to-image-list-button";
import { NavigateToImageOnboardingButton } from "../components/navigate-to-image-onboarding-button.component";

export const ImageHomeScreen = ({ navigation }) => {
  console.debug("📺 🏠 ImageHomeScreen");
  const { user, isLoading } = React.useContext(AuthContext);
  const drawerType = useBreakpointValue({ base: "slide", lg: "permanent" });
  const screenWidth =
    Dimensions.get("window").width - (drawerType === "slide" ? 0 : 250 + 25);

  const numColumns = useBreakpointValue([3, 4, 5]);
  const createdAt = new Date();
  createdAt.setHours(0, 0, 0, 0);

  return (
    <ScreenFrame
      withScrollView={false}
      safeAreaBottom={false}
      footerComponent={<ImageCreate />}
      headerRightComponent={
        <HStack>
          <NavigateToImageOnboardingButton navigation={navigation} />
          {user && <NavigateToImageListButton navigation={navigation} />}
        </HStack>
      }
    >
      {/* <CheckoutButton /> */}
      {isLoading ? (
        <Spinner m={10} key={"spinner"} />
      ) : (
        <VStack flex={1} px={[0, 0, 0, 3]}>
          {user ? (
            <ImageList
              createdAt={createdAt}
              windowSize={8}
              numColumns={numColumns}
              pagingEnabled={false}
              initialNumToRender={8}
              itemSize={screenWidth / numColumns}
              showExamplesIfEmpty={true}
              renderItem={({ item, index }) => (
                <ImageListItem
                  key={index}
                  image={item}
                  size={screenWidth / numColumns}
                  onPress={() => {
                    navigation.navigate("ImageDetail", {
                      imageID: item?.id,
                    });
                  }}
                />
              )}
            />
          ) : (
            <ImageListExamples
              windowSize={40}
              initialNumToRender={40}
              numColumns={numColumns}
              itemSize={screenWidth / numColumns}
            />
          )}
        </VStack>
      )}
    </ScreenFrame>
  );
};
