import { HStack, useBreakpointValue, VStack } from "native-base";
import React from "react";
import { Dimensions, Platform } from "react-native";
// internal hooks
import { useImageURL } from "src/features/images/hooks/useImageURL";
import { useImage } from "../hooks/useImage";
// internal components
import { ImageDetailActions } from "src/features/images/components/image-detail-actions.component";
import { CopyTextIConButton } from "src/theme/buttons/copy-text-icon-button.component";
import { ScreenFrame } from "src/theme/layout/screen-frame.component";
import { Image } from "src/theme/media/image.component";
import { ImageEdit } from "../components/image-edit.component";
import { ImageListRelated } from "../components/image-list-related.component";

const ImageDetailPrompt = ({ prompt }) => (
    <HStack
      shadow={2}
      maxWidth={500}
      borderRadius={"lg"}

    >
      <CopyTextIConButton text={prompt} />
    </HStack>
);
export const ImageDetailScreen = ({ route, navigation }) => {
  const { imageID, parent = "ImageHome" } = route.params;
  const state = navigation.getState();
  const image = useImage(imageID);
  // get image URL from firebase storage
  const imageURL = useImageURL(imageID);
  const drawerType = useBreakpointValue({ base: "slide", lg: "permanent" });
  const screenWidth =
    Dimensions.get("window").width - (drawerType === "slide" ? 0 : 250 + 25);

  // if image doesn't exist, navigate to Home
  React.useEffect(() => {
    if (image.isSuccess && !image.data) {
      navigation.navigate("ImageHome");
    }
  }, [image.isSuccess, image.data]);

  return (
    <ScreenFrame
      px={0}
      flex={1}
      space={3}
      logoIsShown={true}
      withScrollView={true}
      safeAreaBottom={false}
      onBack={() =>
        Platform.OS === "web" && state.index === 0
          ? navigation.navigate(parent)
          : navigation.goBack()
      }
      // footerComponent={<ImageEdit initialImage={image?.data} maxH="auto" />}
      headerRightComponent={
        <ImageDetailActions image={image?.data} imageURL={imageURL?.data} />
      }
    >
      <VStack
        flex={1}
        space={3}
        justifyContent={"center"}
        alignItems="center"
      >
        <ImageListRelated imageID={imageID} parent={"ImageHome"} />

        <Image
          shadow={5}
          size={[
            screenWidth * 0.9,
            screenWidth * 0.7,
            screenWidth * 0.6,
            screenWidth * 0.5,
          ]}
          alt={image?.data?.prompt}
          source={{
            uri: imageURL?.data,
          }}
        />
        {image?.data?.prompt && (
          <ImageDetailPrompt prompt={image?.data?.prompt} />
        )}
      </VStack>
    </ScreenFrame>
  );
};
