import React from "react";
import { useForm } from "react-hook-form";
import { Keyboard, Platform } from "react-native";
import { Box, VStack, HStack, KeyboardAvoidingView } from "native-base";
// internal services
import { TranslationsContext } from "src/services/translations.context";
// internal hooks
import { TextArea } from "src/theme/forms/text-area.component";

import { ImageCreateYamliTextArea } from "./image-create-yamli-text-area.component";
import { ImageVariateButton } from "./image-variate-button.component";
import { ImageEditButton } from "./image-edit-button.component";

export const ImageEdit = React.memo(({ maxH = 75, initialImage = null }) => {
  const { control, handleSubmit } = useForm();
  const [keyboardOpen, setKeyboardOpen] = React.useState(false);
  const { i18n, isYamliEnabled } = React.useContext(TranslationsContext);

  React.useEffect(() => {
    const keyboardWillShowListener = Keyboard.addListener(
      "keyboardWillShow",
      () => setKeyboardOpen(true)
    );
    const keyboardWillHideListener = Keyboard.addListener(
      "keyboardWillHide",
      () => setKeyboardOpen(false)
    );

    return () => {
      keyboardWillShowListener.remove();
      keyboardWillHideListener.remove();
    };
  }, []);

  return (
    <KeyboardAvoidingView
      width={"100%"}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <Box borderTopRadius={"2xl"} overflow={"hidden"}>
        <VStack
          px={3}
          pt={3}
          space={3}
          borderRadius={0}
          _dark={{ bg: "darkBackground" }}
          _light={{ bg: "lightBackground" }}
          safeAreaBottom={!keyboardOpen ? true : undefined}
          pb={keyboardOpen || Platform.OS === "web" ? 3 : 0}
        >
          <HStack space={3} justifyContent={"center"} alignItems="center">
            {isYamliEnabled ? (
              <ImageCreateYamliTextArea
                name="prompt"
                control={control}
                // logic
                h={"auto"}
                maxLength={480}
                maxH={keyboardOpen ? 150 : maxH}
                // rules
                rules={{ maxLength: 480, minLength: 1, required: true }}
                // styling
                placeholder={i18n.t("image_edit_input_placeholder")}
              />
            ) : (
              <TextArea
                name="prompt"
                maxLength={480}
                control={control}
                  numberOfLines={2}
                maxH={keyboardOpen ? 150 : maxH}
                // rules
                rules={{ maxLength: 480, minLength: 1, required: true }}
                // styling
                placeholder={i18n.t("image_edit_input_placeholder")}
              />
            )}
          </HStack>
          <HStack justifyContent={"space-between"}>
            <ImageEditButton
              handleSubmit={handleSubmit}
              initialImage={initialImage}
            />
            <ImageVariateButton initialImage={initialImage} />
          </HStack>
        </VStack>
      </Box>
    </KeyboardAvoidingView>
  );
});
