import React from "react";
import { HStack, VStack } from "native-base";
import { Platform } from "react-native";
// internal hooks
import { useChat } from "../hooks/useChat";
// internal components
import { ScreenFrame } from "src/theme/layout/screen-frame.component";
import { MessageList } from "src/features/messages/components/message-list.component";
import { PersonaList } from "src/features/personas/components/persona-list.component";
import { MessageCreate } from "src/features/messages/components/message-create.component";

export const ChatDetailScreen = ({ route, navigation }) => {
  const { chatId } = route.params;
  const chat = useChat(chatId);

  return (
    <ScreenFrame
      space={0}
      canGoBack={true}
      safeAreaBottom={false}
      withScrollView={false}
      onBack={() =>
        Platform.OS === "web"
          ? navigation.navigate("ChatList")
          : navigation.goBack()
      }
      footerComponent={<MessageCreate chatId={chatId} />}
    >
      <MessageList chat={chat} chatId={chatId} />
    </ScreenFrame>
  );
};
