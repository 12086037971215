import React from "react";
import PropTypes from "prop-types";
import { Box, FlatList, useBreakpointValue } from "native-base";
import { connectInfiniteHits } from "react-instantsearch-native";
import ChatSearchResultListItem from "./chat-search-result-list-item.component";

const ChatSearchResultList = ({ hits, hasMore, refineNext }) => {
  const numColumns = useBreakpointValue([1, 2, 3, 4]);

  return (
    <FlatList
      data={hits}
      key={numColumns}
      numColumns={numColumns}
      scrollEnabled={true}
      keyExtractor={(item) => item.objectID}
      onEndReached={() => hasMore && refineNext()}
      renderItem={({ item }) => (
        <Box flex={1} p={3}>
          <ChatSearchResultListItem attribute="messages" hit={item} />
        </Box>
      )}
    />
  );
};

ChatSearchResultList.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasMore: PropTypes.bool.isRequired,
  refineNext: PropTypes.func.isRequired,
};

export default connectInfiniteHits(ChatSearchResultList);
