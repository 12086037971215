import React from "react";
import {
  useFonts,
  Caveat_400Regular,
  Caveat_500Medium,
  Caveat_600SemiBold,
  Caveat_700Bold,
} from "@expo-google-fonts/caveat";
import {
  NotoSansArabic_100Thin,
  NotoSansArabic_200ExtraLight,
  NotoSansArabic_300Light,
  NotoSansArabic_400Regular,
  NotoSansArabic_500Medium,
  NotoSansArabic_600SemiBold,
  NotoSansArabic_700Bold,
  NotoSansArabic_800ExtraBold,
  NotoSansArabic_900Black,
} from "@expo-google-fonts/noto-sans-arabic";
import {
  Roboto_100Thin,
  Roboto_100Thin_Italic,
  Roboto_300Light,
  Roboto_300Light_Italic,
  Roboto_400Regular,
  Roboto_400Regular_Italic,
  Roboto_500Medium,
  Roboto_500Medium_Italic,
  Roboto_700Bold,
  Roboto_700Bold_Italic,
  Roboto_900Black,
  Roboto_900Black_Italic,
} from "@expo-google-fonts/roboto";
import {
  SignikaNegative_300Light,
  SignikaNegative_400Regular,
  SignikaNegative_600SemiBold,
  SignikaNegative_700Bold,
} from "@expo-google-fonts/signika-negative";

export const FontsProvider = ({ children }) => {
  let [fontsLoaded] = useFonts({
    // Caveat
    Caveat_400Regular,
    Caveat_500Medium,
    Caveat_600SemiBold,
    Caveat_700Bold,
    // NotoSansArabic
    NotoSansArabic_100Thin,
    NotoSansArabic_200ExtraLight,
    NotoSansArabic_300Light,
    NotoSansArabic_400Regular,
    NotoSansArabic_500Medium,
    NotoSansArabic_600SemiBold,
    NotoSansArabic_700Bold,
    NotoSansArabic_800ExtraBold,
    NotoSansArabic_900Black,
    // Roboto
    Roboto_100Thin,
    Roboto_100Thin_Italic,
    Roboto_300Light,
    Roboto_300Light_Italic,
    Roboto_400Regular,
    Roboto_400Regular_Italic,
    Roboto_500Medium,
    Roboto_500Medium_Italic,
    Roboto_700Bold,
    Roboto_700Bold_Italic,
    Roboto_900Black,
    Roboto_900Black_Italic,
    // SignikaNegative
    SignikaNegative_300Light,
    SignikaNegative_400Regular,
    SignikaNegative_600SemiBold,
    SignikaNegative_700Bold,
  });

  if (!fontsLoaded) {
    console.debug("⛲️ Fonts");
    return null;
  }
  return <>{children}</>;
};
