import { Ionicons } from "@expo/vector-icons";
import {
  Box,
  Button,
  Text,
  Pressable,
  VStack,
  HStack,
  Divider,
  Icon,
  Popover,
} from "native-base";
import React from "react";
import { Linking } from "react-native";
import { useMutation } from "react-query";

// internal
import { AxiosContext } from "src/services/axios.context";
const useCheckout = () => {
  const { client } = React.useContext(AxiosContext);

  return useMutation(
    () => {
      return client.post(`stripe/checkout/`);
    },
    {
      onSuccess: (resp) => {
        return resp?.data?.data;
      },
      onError: (error) => {
        console.error(error.response?.data);
      },
    }
  );
};

export const CheckoutButton = () => {
  const checkout = useCheckout();

  React.useEffect(() => {
    if (checkout?.data?.data) {
      console.debug("🌃  Checkout URL: ", checkout.data.data);
      Linking.openURL(checkout.data.data);
    }
  }, [checkout?.data?.data]);

  return (
    <Box
      _dark={{
        bg: {
          linearGradient: {
            colors: ["yellow.400", "yellow.500", "yellow.400"],
            start: [0, 0],
            end: [1, 0],
          },
        },
      }}
      _light={{
        bg: {
          linearGradient: {
            colors: ["yellow.200", "yellow.300", "yellow.200"],
            start: [0, 0],
            end: [1, 0],
          },
        },
      }}
    >
      <HStack width="100%" p={3} space={5} justifyContent={"center"}>
        <VStack>
          <Text
            fontSize={"xl"}

            color={"darkText"}
            textAlign={"center"}
            letterSpacing={"lg"}
          >
            Premium Plan{" "}
          </Text>
          <Text
            fontSize={"sm"}
            color={"darkText"}
            fontWeight="normal"
            textAlign={"center"}
          >
            Unlimited Access
          </Text>
          <Text strikeThrough color={"darkText"} textAlign={"center"}>
            14.99 EUR
          </Text>
          <Text fontSize={"lg"} color={"darkText"} textAlign={"center"}>
            0.00 EUR <Text fontSize={"xs"}>for 7 days</Text>
          </Text>
        </VStack>
        <Divider orientation="vertical" color="gray.100" />
        <VStack
          space={1}
          maxW="50%"
          alignItems="center"
          justifyContent={"center"}
        >
          <Button
            size={"lg"}
            bg={"gray.100"}
            borderRadius={"md"}
            _text={{
              color: "darkText",
            }}
            _pressed={{
              bg: "gray.200",
            }}
            _hover={{
              bg: "gray.200",
            }}
            _spinner={{
              color: "darkText",
            }}
            rightIcon={
              <Icon as={Ionicons} name="open" size={5} color="darkText" />
            }
            // logic
            onPress={checkout.mutate}
            isLoading={checkout.isLoading}
          >
            Subscribe Now
          </Button>
          <Text fontSize={"2xs"}>
            or{" "}
            <Popover
              trigger={(triggerProps) => (
                <Pressable {...triggerProps}>
                  <Text underline>click here </Text>
                </Pressable>
              )}
            >
              <Popover.Content mx={1}>
                <Popover.Body>
                  Unleash the full potential of our AI-powered art and chat
                  tools with our premium plan. Generate unlimited AI images in
                  your native language or engage in dynamic chats with our
                  advanced AI Assistant. Enjoy a 7-day free trial and cancel
                  anytime without being charged. It's the perfect way to
                  experience the full power of our AI tools, risk-free.
                </Popover.Body>
              </Popover.Content>
            </Popover>
            for more information
          </Text>
        </VStack>
      </HStack>
    </Box>
  );
};
