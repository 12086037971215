import React from "react";
import { Progress } from "native-base";

export const TimedProgressBar = ({ seconds, steps = 0.25 }) => {
  const [progress, setProgress] = React.useState(0);
  const interval = (seconds / (100 / steps)) * 992;

  React.useEffect(() => {
    if (progress < 100) {
      const timeout = setTimeout(() => setProgress(progress + steps), interval);
      return () => clearTimeout(timeout);
    }
  }, [progress]);
  return <Progress value={progress} colorScheme="primary" height={1} />;
};
