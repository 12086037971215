import React from "react";
import { Platform } from "react-native";
import {
  Input,
  VStack,
  Button,
  FormControl,
  KeyboardAvoidingView,
} from "native-base";
// internal
import { Logo } from "src/theme/media/logo.component";
import { AuthContext } from "src/services/auth.context";
import { ScreenFrame } from "src/theme/layout/screen-frame.component";
import { TranslationsContext } from "src/services/translations.context";

export const UserRegisterScreen = ({ navigation }) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const { i18n } = React.useContext(TranslationsContext);
  const { onRegister, isLoading, user } = React.useContext(AuthContext);

  const onRegisterPress = () => {
    onRegister(email, password);
  };

  React.useEffect(() => {
    if (user) {
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        navigation.navigate("ImageNavigator");
      }
    }
  }, [user]);

  return (
    <KeyboardAvoidingView
      flex={1}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <ScreenFrame
        py={5}
        px={5}
        onBack={() => navigation.navigate("UserLogin")}
      >
        <VStack
          flex={1}
          maxW={500}
          width="100%"
          space={"md"}
          borderRadius="lg"
          alignSelf={"center"}
          justifyContent={"center"}
        >
          <Logo withNavigation={false} />
          <VStack space={5}>
            <Input
              size={"lg"}
              type="text"
              value={email}
              variant={"filled"}
              borderRadius={"sm"}
              autoCapitalize={"none"}
              onChangeText={setEmail}
              keyboardType="email-address"
              placeholder={i18n.t(
                "user_login_or_register_email_input_placeholder"
              )}
            />
            <Input
              size={"lg"}
              type="password"
              value={password}
              variant={"filled"}
              borderRadius={"sm"}
              keyboardType="default"
              onChangeText={setPassword}
              placeholder={i18n.t(
                "user_login_or_register_password_input_placeholder"
              )}
            />
            <Input
              size={"lg"}
              type="password"
              variant={"filled"}
              borderRadius={"sm"}
              keyboardType="default"
              value={passwordConfirmation}
              onChangeText={setPasswordConfirmation}
              placeholder={i18n.t(
                "user_login_or_register_password_confirmation_input_placeholder"
              )}
            />
          </VStack>
          <FormControl>
            <VStack space={5}>
              <Button
                variant={"solid"}
                borderRadius={"sm"}
                isLoading={isLoading}
                onPress={onRegisterPress}
              >
                {i18n.t("user_register")}
              </Button>
              <Button
                variant={"ghost"}
                borderRadius={"sm"}
                onPress={() => {
                  navigation.navigate("UserLogin");
                }}
              >
                {i18n.t("general_go_back_button")}
              </Button>
            </VStack>
          </FormControl>
        </VStack>
      </ScreenFrame>
    </KeyboardAvoidingView>
  );
};
