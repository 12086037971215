import React from "react";
import { Box, HStack, Spinner, VStack } from "native-base";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-native";
// internal
import ChatSearchInput from "./chat-search-input.component";
import ChatSearchResultList from "./chat-search-result-list.component";
import { useUserDetails } from "src/features/users/hooks/useUserDetails";

export const ChatSearch = ({ setSearchTerm, searchTerm }) => {
  const userDetails = useUserDetails();
  const searchClient = algoliasearch(
    "1L6DKURPUI",
    userDetails?.search_api_key,
    {
      queryParameters: {
        hitsPerPage: 5,
      },
    }
  );
  if (userDetails) {
    return (
      <InstantSearch
        indexName="chats"
        searchClient={searchClient}
        onSearchStateChange={setSearchTerm}
      >
        <Box alignSelf="center" maxWidth={500} width={"100%"}>
          <ChatSearchInput />
        </Box>

        <Box pt={5} />
        {searchTerm?.query && <ChatSearchResultList />}
      </InstantSearch>
    );
  } else return <Spinner />;
};
