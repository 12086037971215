import axios from "axios";
import { useMutation } from "react-query";

export const useYamli = () => {
  return useMutation(
    (word) => {
      return axios
        .get(
          `https://api.yamli.com/transliterate.ashx?word=${word}&tool=api&account_id=000006&prot=https%3A&hostname=www.yamli.com&path=%2F&build=5515`
        )
        .then((resp) => {
          return { r: resp.data?.r?.split(/[|\d/]+/), w: resp?.data?.w };
        });
    },
    {
      onError: (error) => {
        Toast.show({
          duration: 5000,
          bg: "danger.900",
          title: i18n.t("general_error_toast_title"),
          description: i18n.t("image_create_error_toast_description"),
        });
      },
    }
  );
};
